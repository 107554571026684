import { Skeleton } from "@mui/material";

const DiagramSkeleton = () => {
  const leftOffset = 50;
  const middleOffset = 250;
  const rightOffset = 450;
  const shapeWidth = 100;
  const shapeHeight = 50;
  const lineLength = 100;
  const lineWidth = 2;
  const verticalLineOffset = 49;
  const horizontalLineOffset = 25;
  return (
    <>
      {/* Row 1 */}
      <Skeleton
        variant="rectangular"
        width={shapeWidth}
        height={shapeHeight}
        sx={{
          marginLeft: `${middleOffset}px`,
          marginTop: "50px",
        }}
      />
      {/* Row 2 */}
      <Skeleton
        variant="rectangular"
        width={lineWidth}
        height={lineLength}
        sx={{
          marginLeft: `${middleOffset + verticalLineOffset}px`,
        }}
      />
      {/* Row 3 */}
      <Skeleton
        variant="rectangular"
        width={shapeWidth}
        height={shapeHeight}
        sx={{
          marginLeft: `${leftOffset}px`,
        }}
      />
      <Skeleton
        variant="rectangular"
        width={lineLength}
        height={lineWidth}
        sx={{
          marginLeft: `${leftOffset + shapeWidth}px`,
          marginTop: `-${horizontalLineOffset}px`,
        }}
      />
      <Skeleton
        variant="rectangular"
        width={shapeWidth}
        height={shapeHeight}
        sx={{
          marginTop: `-${horizontalLineOffset + lineWidth}px`,
          marginLeft: `${middleOffset}px`,
        }}
      />
      <Skeleton
        variant="rectangular"
        width={lineLength}
        height={lineWidth}
        sx={{
          marginLeft: `${middleOffset + shapeWidth}px`,
          marginTop: `-${horizontalLineOffset}px`,
        }}
      />
      <Skeleton
        variant="rectangular"
        width={shapeWidth}
        height={shapeHeight}
        sx={{
          marginLeft: `${rightOffset}px`,
          marginTop: `-${horizontalLineOffset + lineWidth}px`,
        }}
      />
      {/* Row 4 */}
      <Skeleton
        variant="rectangular"
        width={lineWidth}
        height={lineLength}
        sx={{
          marginLeft: `${leftOffset + verticalLineOffset}px`,
        }}
      />
      <Skeleton
        variant="rectangular"
        width={lineWidth}
        height={lineLength}
        sx={{
          marginLeft: `${rightOffset + verticalLineOffset}px`,
          marginTop: `-${lineLength}px`,
        }}
      />
      {/* Row 5 */}
      <Skeleton
        variant="rectangular"
        width={shapeWidth}
        height={shapeHeight}
        sx={{
          marginLeft: `${leftOffset}px`,
        }}
      />
      <Skeleton
        variant="rectangular"
        width={shapeWidth}
        height={shapeHeight}
        sx={{
          marginLeft: `${rightOffset}px`,
          marginTop: `-${shapeHeight}px`,
        }}
      />
    </>
  );
};

export default DiagramSkeleton;
