import { useCallback } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSigninDialog,
  openSigninDialog,
  selectSignInDialogOpen,
  selectUser,
  selectUserLoaded,
  signin,
  signout,
} from "./authenticationSlice";

const SignInDialog = () => {
  const dispatch = useDispatch();
  const shareModalOpen = useSelector(selectSignInDialogOpen);
  const handleClose = useCallback(
    () => dispatch(closeSigninDialog()),
    [dispatch]
  );
  const handleSignin = useCallback(() => {
    dispatch(openSigninDialog());
    // We need to render the dialog before showing the login buttons, hence the timeout
    setTimeout(() => dispatch(signin()), 0);
  }, [dispatch]);
  const handleSignout = useCallback(() => dispatch(signout()), [dispatch]);
  const user = useSelector(selectUser);
  const userLoaded = useSelector(selectUserLoaded);
  return (
    <>
      {user && !user.isAnonymous && (
        <Button
          variant="contained"
          onClick={handleSignout}
          sx={{ width: "75px" }}
        >
          Logout
        </Button>
      )}
      {(!user || user.isAnonymous) && (
        <Button
          variant="contained"
          onClick={handleSignin}
          sx={{ width: "75px" }}
          disabled={!userLoaded}
        >
          {userLoaded ? "Login" : <CircularProgress size={23} />}
        </Button>
      )}
      <Dialog
        open={shareModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <div id="firebaseui-auth-container"></div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SignInDialog;
