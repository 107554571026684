import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rect } from "react-konva";
import { darkTheme, diagramTheme } from "../../../theme";
import { ITEM_TYPES } from "../../../utils/diagramUtils";
import { selectSelection } from "../../../features/selection/selectionSlice";
import { useForceUpdate } from "../../../hooks/forceUpdate";
import ShapeText from "../ShapeText/ShapeText";
import {
  selectEditedElementId,
  startEdit,
} from "../../../features/editing/editingSlice";

const SelectableRect = ({
  shape,
  editable,
  hoverable,
  selected,
  onOpenTools,
}) => {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const selection = useSelector(selectSelection);
  const editedElementId = useSelector(selectEditedElementId);
  const editing = editedElementId === shape.id;
  const [hovering, setHovering] = useState(false);
  const shapeRef = useRef();
  const handleContextMenu = useCallback(
    (e) => {
      e.evt.preventDefault();
      e.evt.stopPropagation();
      onOpenTools(shape.id, { left: e.evt.clientX, top: e.evt.clientY });
    },
    [onOpenTools, shape.id]
  );
  const handleTextEdit = useCallback(() => {
    if (
      editable &&
      Object.keys(selection).length === 1 &&
      !!selection[shape.id]
    ) {
      dispatch(startEdit(shape.id));
    }
  }, [dispatch, editable, selection, shape.id]);
  useEffect(() => forceUpdate(), [shape, editing, forceUpdate]);
  return (
    <>
      <Rect
        ref={shapeRef}
        id={shape.id}
        x={shape.x}
        y={shape.y}
        width={shape.width}
        height={shape.height}
        fill={
          shape.type === ITEM_TYPES.RECT
            ? diagramTheme().shapeColor
            : diagramTheme().containerColor
        }
        stroke={
          selected
            ? darkTheme.palette.secondary.main
            : hovering
            ? darkTheme.palette.primary.main
            : shape.type === ITEM_TYPES.CONTAINER
            ? diagramTheme().shapeColor
            : "transparent"
        }
        strokeWidth={1}
        onMouseEnter={() => hoverable && setHovering(true)}
        onMouseLeave={() => hoverable && setHovering(false)}
        onContextMenu={handleContextMenu}
        onDblClick={handleTextEdit}
        onDblTap={handleTextEdit}
      />
      <ShapeText
        shape={shape}
        selected={selected}
        hovering={hovering}
        editing={editing}
      />
    </>
  );
};

SelectableRect.defaultProps = {
  hoverable: true,
};

export default SelectableRect;
