import { useCallback, useState } from "react";
import { Layer, Rect } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import {
  selectConnections,
  selectDiagram,
  selectDiagramId,
  selectShapes,
  setConnections,
  setShapes,
  updateElements,
} from "../../features/diagram/diagramSlice";

import {
  addToSelection,
  removeFromSelection,
  resetSelection,
  selectSelection,
} from "../../features/selection/selectionSlice";
import SelectableLine from "../DiagramElements/SelectableLine/SelectableLine";
import SelectableRect from "../DiagramElements/SelectableRect/SelectableRect";
import { snapToGrid } from "./GridLayer";
import {
  filterNotNull,
  getMouseStagePosition,
  ITEM_TYPES,
} from "../../utils/diagramUtils";
import keyMirror from "keymirror";
import Decision from "../DiagramElements/Decision/Decision";
import OrthogonalConnection from "../DiagramElements/OrthogonalConnection/OrthogonalConnection";
import Actor from "../DiagramElements/Actor/Actor";

export const MODES = keyMirror({
  NONE: null,
  DRAG_OR_SELECTION: null, // FIXME: To be replaced by below 2
  CLICK_OR_SELECTION: null,
  CLICK_OR_DRAG: null,
  DRAG: null,
});

const ElementsLayer = ({ editable, stage, onOpenTools }) => {
  // Redux Data
  const dispatch = useDispatch();
  const diagramId = useSelector(selectDiagramId);
  const diagram = useSelector(selectDiagram);
  const shapes = useSelector(selectShapes);
  const connections = useSelector(selectConnections);
  const selection = useSelector(selectSelection);

  // Local Data
  const [mode, setMode] = useState(MODES.NONE);
  const [dragStart, setDragStart] = useState(null);
  const [startingElements, setStartingElements] = useState(null);

  ////////////////////////////////
  // MOUSE DOWN
  ////////////////////////////////
  const handleMouseDown = useCallback(
    (e) => {
      const id = e.target?.attrs?.id;
      if (id) {
        // We have clicked on one of the shapes, it could either be a click or a drag
        // We will know once the mouse move (or doesn't move)
        setMode(MODES.DRAG_OR_SELECTION);
        if (!selection[id]) {
          if (!e.evt.shiftKey && Object.keys(selection).length > 0) {
            dispatch(resetSelection());
          }
          dispatch(addToSelection([id]));
        } else if (e.evt.shiftKey) {
          // FIXME: Shouldn't remove shape from selection on a drag,
          // which we don't know yet here. Move this logic to onMouseUp.
          dispatch(removeFromSelection([id]));
        }
      }
    },
    [dispatch, selection, setMode]
  );

  ////////////////////////////////
  // MOUSE MOVE
  ////////////////////////////////
  const handleMouseMove = useCallback(
    (e) => {
      if (e.evt.buttons === 1) {
        if (mode === MODES.DRAG_OR_SELECTION) {
          if (Object.keys(selection).length > 0) {
            setMode(MODES.DRAG);
            setDragStart(getMouseStagePosition(e, stage));
            setStartingElements({
              ...cloneDeep(shapes),
              ...cloneDeep(connections),
            });
          }
        } else if (mode === MODES.DRAG && editable) {
          const coords = getMouseStagePosition(e, stage);
          const moveBy = {
            x: snapToGrid(coords.x - dragStart.x),
            y: snapToGrid(coords.y - dragStart.y),
          };
          // Shapes just move by x and y
          const shapesToUpdate = Object.keys(selection)
            .map((id) => shapes[id])
            .filter(filterNotNull)
            .map((element) => {
              const x = startingElements[element.id].x + moveBy.x;
              const y = startingElements[element.id].y + moveBy.y;
              return x !== element.x || y !== element.y
                ? { id: element.id, shape: { x, y } }
                : null;
            })
            .filter(filterNotNull);
          if (shapesToUpdate.length > 0) {
            dispatch(setShapes(shapesToUpdate));
          }
          // Connections need all their points moved by the offsets
          const connectionsToUpdate = Object.keys(selection)
            .map((id) => connections[id])
            .filter(filterNotNull)
            .filter((element) => !element.startAnchor && !element.endAnchor)
            .map((element) => {
              const startX = startingElements[element.id].start.x + moveBy.x;
              const startY = startingElements[element.id].start.y + moveBy.y;
              const endX = startingElements[element.id].end.x + moveBy.x;
              const endY = startingElements[element.id].end.y + moveBy.y;
              return startX !== element.start.x ||
                startY !== element.start.y ||
                endX !== element.end.x ||
                endY !== element.end.y
                ? {
                    id: element.id,
                    connection: {
                      start: { x: startX, y: startY },
                      end: { x: endX, y: endY },
                    },
                  }
                : null;
            })
            .filter(filterNotNull);
          if (connectionsToUpdate.length > 0)
            dispatch(setConnections(connectionsToUpdate));
        }
      }
    },
    [
      connections,
      dispatch,
      dragStart,
      editable,
      mode,
      selection,
      setMode,
      shapes,
      stage,
      startingElements,
    ]
  );

  ////////////////////////////////
  // MOUSE UP
  ////////////////////////////////
  const handleMouseUp = useCallback(
    (e) => {
      if (mode === MODES.DRAG && dragStart && editable) {
        const ids = Object.keys(selection);
        if (ids.length > 0) {
          dispatch(
            updateElements({
              diagramId,
              elements: ids.map((id) => shapes[id] || connections[id]),
            })
          );
        }
      }
      setDragStart(null);
      setMode(MODES.NONE);
    },
    [
      connections,
      diagramId,
      dispatch,
      dragStart,
      editable,
      mode,
      selection,
      setMode,
      shapes,
    ]
  );

  ////////////////////////////////
  // RENDERING
  ////////////////////////////////
  return (
    <Layer
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {stage && !!dragStart && (
        <Rect
          x={-stage.x() / stage.scaleX()}
          y={-stage.y() / stage.scaleY()}
          width={stage.width() / stage.scaleX()}
          height={stage.height() / stage.scaleY()}
        />
      )}
      {diagram?.orderedElements?.map((elementId) => {
        let element = shapes[elementId];
        if (element) {
          if (
            element.type === ITEM_TYPES.RECT ||
            element.type === ITEM_TYPES.CONTAINER
          )
            return (
              <SelectableRect
                key={elementId}
                shape={element}
                editable={editable}
                selected={!!selection[elementId]}
                onOpenTools={onOpenTools}
              />
            );
          else if (element.type === ITEM_TYPES.DECISION)
            return (
              <Decision
                key={elementId}
                shape={element}
                editable={editable}
                selected={!!selection[elementId]}
                onOpenTools={onOpenTools}
              />
            );
          else if (element.type === ITEM_TYPES.ACTOR)
            return (
              <Actor
                key={elementId}
                shape={element}
                selected={!!selection[elementId]}
                onOpenTools={onOpenTools}
              />
            );
        } else {
          element = connections[elementId];
          if (element)
            if (element.type === ITEM_TYPES.LINE)
              return (
                <SelectableLine
                  key={elementId}
                  connection={element}
                  editable={editable}
                  selected={!!selection[elementId]}
                  onOpenTools={onOpenTools}
                />
              );
            else if (element.type === ITEM_TYPES.ORTHOGONAL_CONNECTION)
              return (
                <OrthogonalConnection
                  key={elementId}
                  connection={element}
                  editable={editable}
                  selected={!!selection[elementId]}
                  onOpenTools={onOpenTools}
                />
              );
        }
        return null;
      })}
    </Layer>
  );
};

export default ElementsLayer;
