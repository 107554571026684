import { useCallback, useEffect, useRef, useState } from "react";
import { RegularPolygon } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { darkTheme, diagramTheme } from "../../../theme";
import { selectSelection } from "../../../features/selection/selectionSlice";
import { useForceUpdate } from "../../../hooks/forceUpdate";
import ShapeText from "../ShapeText/ShapeText";
import {
  selectEditedElementId,
  startEdit,
} from "../../../features/editing/editingSlice";

const Decision = ({ shape, editable, hoverable, selected, onOpenTools }) => {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const selection = useSelector(selectSelection);
  const editedElementId = useSelector(selectEditedElementId);
  const editing = editedElementId === shape.id;
  const [hovering, setHovering] = useState(false);
  const shapeRef = useRef();
  const handleContextMenu = useCallback(
    (e) => {
      e.evt.preventDefault();
      e.evt.stopPropagation();
      onOpenTools(shape.id, { left: e.evt.clientX, top: e.evt.clientY });
    },
    [onOpenTools, shape.id]
  );
  const handleTextEdit = useCallback(() => {
    if (
      editable &&
      Object.keys(selection).length === 1 &&
      !!selection[shape.id]
    ) {
      dispatch(startEdit(shape.id));
    }
  }, [dispatch, editable, selection, shape.id]);
  useEffect(() => forceUpdate(), [shape, editing, forceUpdate]);
  return (
    <>
      <RegularPolygon
        ref={shapeRef}
        id={shape.id}
        x={shape.x + shape.width / 2}
        y={shape.y + shape.height / 2}
        sides={4}
        radius={shape.height / 2}
        fill={diagramTheme().shapeColor}
        stroke={
          selected
            ? darkTheme.palette.secondary.main
            : hovering
            ? darkTheme.palette.primary.main
            : diagramTheme().shapeColor
        }
        strokeWidth={1}
        onContextMenu={handleContextMenu}
        onMouseEnter={() => hoverable && setHovering(true)}
        onMouseLeave={() => hoverable && setHovering(false)}
        onDblClick={handleTextEdit}
        onDblTap={handleTextEdit}
      />
      <ShapeText
        shape={shape}
        selected={selected}
        hovering={hovering}
        editing={editing}
      />
    </>
  );
};

Decision.defaultProps = {
  hoverable: true,
};

export default Decision;
