import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import DiagramPage from "./pages/DiagramPage";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { darkTheme } from "./theme";
import Pricing from "./pages/Pricing";

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="pricing" element={<Pricing />} />
        <Route
          path="diagrams/:diagramId"
          element={<DiagramPage readonly={false} />}
        />
        <Route path="embeds/:diagramId" element={<DiagramPage readonly />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
