import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import union from "lodash.union";
import keyBy from "lodash.keyby";
import { db } from "../config/firebase";
import {
  setShapesError,
  setShapesLoading,
  clearShapes,
  clearConnections,
  setConnectionsLoading,
  setConnectionsError,
  setDiagramLoading,
  setDiagramError,
  setDiagram,
  setDiagramId,
  selectDiagram,
  selectConnections,
  selectShapes,
  updateDiagram,
  selectDiagramLoading,
  selectConnectionsLoading,
  selectShapesLoading,
  resetErrors,
  setShapes,
  setConnections,
  setConnectionsDoneLoading,
  setShapesDoneLoading,
} from "../features/diagram/diagramSlice";
import {
  addToSelection,
  resetSelection,
} from "../features/selection/selectionSlice";

const mapChangeToShape = (change) => ({
  id: change.doc.id,
  shape: change.doc.data(),
});
const mapChangeToConnection = (change) => ({
  id: change.doc.id,
  connection: change.doc.data(),
});

// TODO: This would be better in a thunk..?
export const useLoadDiagram = (diagramId) => {
  const dispatch = useDispatch();
  const shapesInitialized = useRef(false);
  const connectionsInitialized = useRef(false);

  // **************
  // DIAGRAMS
  // **************
  useEffect(() => {
    dispatch(resetErrors());
    dispatch(setDiagramId(diagramId));
    dispatch(setDiagramLoading(true));
    return onSnapshot(
      query(doc(db, `diagrams/${diagramId}`)),
      (doc) => {
        dispatch(
          setDiagram({
            diagram: doc.data(),
          })
        );
      },
      (error) => {
        dispatch(setDiagramError(error.toString()));
      }
    );
  }, [diagramId, dispatch]);

  // **************
  // SHAPES
  // **************
  useEffect(() => {
    dispatch(clearShapes());
    dispatch(setShapesLoading());
    const unsubscribe = onSnapshot(
      query(collection(db, `diagrams/${diagramId}/shapes`)),
      (snapshot) => {
        const shapesToAdd = snapshot
          .docChanges()
          .filter(
            (change) => change.type === "added" || change.type === "modified"
          )
          .map(mapChangeToShape);
        const shapesToSelect = snapshot
          .docChanges()
          .filter((change) => change.type === "added")
          .map(mapChangeToShape);
        const shapesToDelete = snapshot
          .docChanges()
          .filter((change) => change.type === "removed")
          .map(mapChangeToShape);
        if (shapesToAdd.length > 0) dispatch(setShapes(shapesToAdd));
        if (shapesToSelect.length > 0)
          dispatch(addToSelection(shapesToSelect.map((s) => s.id)));
        if (shapesToDelete.length > 0)
          dispatch(clearShapes(shapesToDelete.map((s) => s.id)));
        dispatch(setShapesDoneLoading());
        if (!shapesInitialized.current) {
          dispatch(resetSelection());
          shapesInitialized.current = true;
        }
      },
      (error) => {
        dispatch(setShapesError(error.toString()));
      }
    );
    return unsubscribe;
  }, [diagramId, dispatch]);

  // **************
  // CONNECTIONS
  // **************
  useEffect(() => {
    dispatch(clearConnections());
    dispatch(setConnectionsLoading(true));
    const unsubscribe = onSnapshot(
      query(collection(db, `diagrams/${diagramId}/connections`)),
      (snapshot) => {
        const connectionsToAdd = snapshot
          .docChanges()
          .filter(
            (change) => change.type === "added" || change.type === "modified"
          )
          .map(mapChangeToConnection);
        // FIXME: This causes shapes created by another user to be selected
        // even on someone else's screen, no good
        const connectionsToSelect = snapshot
          .docChanges()
          .filter((change) => change.type === "added")
          .map(mapChangeToConnection);
        const connectionsToDelete = snapshot
          .docChanges()
          .filter((change) => change.type === "removed")
          .map(mapChangeToConnection);
        if (connectionsToAdd.length > 0)
          dispatch(setConnections(connectionsToAdd));
        if (connectionsToSelect.length > 0)
          dispatch(addToSelection(connectionsToSelect.map((s) => s.id)));
        if (connectionsToDelete.length > 0)
          dispatch(clearConnections(connectionsToDelete.map((s) => s.id)));
        dispatch(setConnectionsDoneLoading());
        if (!connectionsInitialized.current) {
          dispatch(resetSelection());
          connectionsInitialized.current = true;
        }
      },
      (error) => {
        dispatch(setConnectionsError(error.toString()));
      }
    );
    return unsubscribe;
  }, [diagramId, dispatch]);

  const diagram = useSelector(selectDiagram);
  const shapes = useSelector(selectShapes);
  const connections = useSelector(selectConnections);
  const diagramLoading = useSelector(selectDiagramLoading);
  const shapesLoading = useSelector(selectShapesLoading);
  const connectionsLoading = useSelector(selectConnectionsLoading);
  useEffect(() => {
    if (
      diagram &&
      shapes &&
      connections &&
      !diagramLoading &&
      !shapesLoading &&
      !connectionsLoading
    ) {
      if (
        !diagram.orderedElements ||
        diagram.orderedElements.length !==
          Object.values(shapes).length + Object.values(connections).length
      ) {
        const shapesKeyedById = keyBy(shapes, "id");
        const connectionsKeyedById = keyBy(connections, "id");
        const orderedElements =
          diagram.orderedElements?.filter(
            (id) => !!shapesKeyedById[id] || !!connectionsKeyedById[id]
          ) || [];
        const elementsKeyedById = keyBy(orderedElements, (e) => e);
        const newOrderedElements = union(
          orderedElements,
          Object.values(shapes)
            .map((s) => s.id)
            .filter((id) => !elementsKeyedById[id]),
          Object.values(connections)
            .map((s) => s.id)
            .filter((id) => !elementsKeyedById[id])
        );
        dispatch(
          updateDiagram({
            diagramId,
            diagram: {
              orderedElements: newOrderedElements,
            },
          })
        );
      }
    }
  }, [
    diagram,
    shapes,
    connections,
    dispatch,
    diagramId,
    diagramLoading,
    shapesLoading,
    connectionsLoading,
  ]);
};
