import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editedElementId: null,
};

export const slice = createSlice({
  name: "editing",
  initialState,
  reducers: {
    startEdit: (state, { payload: id }) => {
      state.editedElementId = id;
    },
    stopEdit: (state) => {
      state.editedElementId = null;
    },
  },
});

export const { startEdit, stopEdit } = slice.actions;

export const selectEditedElementId = (state) => state.editing.editedElementId;

export const selectEditedElement = (state) =>
  state.diagram.shapes[state.editing.editedElementId];

export default slice.reducer;
