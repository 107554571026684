import { Arrow } from "react-konva";
import { darkTheme, diagramTheme } from "../../theme";

const strokeWidth = 3;

const BaseLine = ({
  id,
  points,
  startArrow,
  endArrow,
  selected,
  hovering,
  setHovering,
  handleContextMenu,
  baseColor,
  strokeScale,
}) => {
  return (
    <>
      <Arrow
        id={id}
        x={0}
        y={0}
        points={points}
        pointerAtBeginning={!!startArrow}
        pointerAtEnding={!!endArrow}
        stroke={
          selected
            ? darkTheme.palette.secondary.main
            : hovering
            ? darkTheme.palette.primary.main
            : "transparent"
        }
        strokeWidth={strokeWidth * strokeScale}
        cornerRadius={4}
        pointerLength={4}
        pointerWidth={4}
        lineCap="round"
        listening={false}
      />
      <Arrow
        id={id}
        x={0}
        y={0}
        points={points}
        pointerAtBeginning={!!startArrow}
        pointerAtEnding={!!endArrow}
        fill={baseColor ? baseColor : diagramTheme().lineColor}
        stroke={baseColor ? baseColor : diagramTheme().lineColor}
        strokeWidth={strokeWidth * strokeScale - (selected || hovering ? 2 : 1)}
        cornerRadius={4}
        pointerLength={4}
        pointerWidth={4}
        lineCap="round"
        listening={false}
      />
      <Arrow
        x={0}
        y={0}
        id={id}
        points={points}
        pointerAtBeginning={!!startArrow}
        pointerAtEnding={!!endArrow}
        stroke="transparent"
        strokeWidth={strokeWidth * strokeScale + 4}
        cornerRadius={4}
        pointerLength={4}
        pointerWidth={4}
        onContextMenu={handleContextMenu}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
    </>
  );
};

BaseLine.defaultProps = {
  strokeScale: 1,
};

export default BaseLine;
