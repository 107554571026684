import { useRef } from "react";
import { Text } from "react-konva";
import { diagramTheme } from "../../../theme";

export const SHAPE_TEXT_CONSTANTS = {
  textHorizontalPadding: 10,
  lineHeight: 1,
  fontFamily: "Arial",
  align: "center",
};

const ShapeText = ({ shape, selected, hovering, editing }) => {
  const textRef = useRef();
  return (
    <Text
      ref={textRef}
      text={shape.text ? shape.text : selected && hovering ? "Add text" : ""}
      x={shape.x + SHAPE_TEXT_CONSTANTS.textHorizontalPadding}
      y={
        shape.y +
        shape.height / 2 -
        ((textRef.current?.textHeight ?? 0) / 2) *
          (textRef.current?.textArr?.length ?? 1)
      }
      width={shape.width - 2 * SHAPE_TEXT_CONSTANTS.textHorizontalPadding}
      fill={shape.text ? diagramTheme().textColor : diagramTheme().lineColor}
      visible={!editing}
      listening={false}
      align={SHAPE_TEXT_CONSTANTS.align}
      verticalAlign="middle"
      shadowForStrokeEnabled={false}
    />
  );
};

export default ShapeText;
