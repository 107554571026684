import { Layer, Text } from "react-konva";
import { isLocal } from "../../utils/envUtils";

const DebugLayer = ({ stage, debugPosition }) => {
  return isLocal() && stage && debugPosition ? (
    <Layer>
      <Text
        x={debugPosition.x + 20 / stage.scaleX()}
        y={debugPosition.y + 20 / stage.scaleX()}
        fontSize={12 / stage.scaleX()}
        text={`${debugPosition.x},${debugPosition.y}`}
        fill="white"
      />
    </Layer>
  ) : null;
};

export default DebugLayer;
