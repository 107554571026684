import { useCallback, useState } from "react";
import { Circle } from "react-konva";
import { darkTheme, diagramTheme } from "../../../theme";
import BaseLine from "../../BaseLine/BaseLine";

const Actor = ({ shape, hoverable, selected, onOpenTools }) => {
  const [hovering, setHovering] = useState(false);
  const handleContextMenu = useCallback(
    (e) => {
      e.evt.preventDefault();
      e.evt.stopPropagation();
      onOpenTools(shape.id, { left: e.evt.clientX, top: e.evt.clientY });
    },
    [onOpenTools, shape.id]
  );
  return (
    <>
      <BaseLine
        id={shape.id}
        points={[
          shape.x + shape.width / 2,
          shape.y + (shape.height / 3) * 2,
          shape.x,
          shape.y + shape.height,
        ]}
        selected={selected}
        hovering={hovering}
        setHovering={setHovering}
        handleContextMenu={handleContextMenu}
        baseColor={diagramTheme().lineColor}
        strokeScale={shape.height / 64}
      />
      <BaseLine
        id={shape.id}
        points={[
          shape.x + shape.width / 2,
          shape.y + (shape.height / 3) * 2,
          shape.x + shape.width,
          shape.y + shape.height,
        ]}
        selected={selected}
        hovering={hovering}
        setHovering={setHovering}
        handleContextMenu={handleContextMenu}
        baseColor={diagramTheme().lineColor}
        strokeScale={shape.height / 64}
      />
      <BaseLine
        id={shape.id}
        points={[
          shape.x,
          shape.y + (shape.height / 5) * 2,
          shape.x + shape.width,
          shape.y + (shape.height / 5) * 2,
        ]}
        selected={selected}
        hovering={hovering}
        setHovering={setHovering}
        handleContextMenu={handleContextMenu}
        baseColor={diagramTheme().lineColor}
        strokeScale={shape.height / 64}
      />
      <BaseLine
        id={shape.id}
        points={[
          shape.x + shape.width / 2,
          shape.y + shape.width / 2,
          shape.x + shape.width / 2,
          shape.y + (shape.height / 3) * 2.05,
        ]}
        selected={selected}
        hovering={hovering}
        setHovering={setHovering}
        handleContextMenu={handleContextMenu}
        baseColor={diagramTheme().lineColor}
        strokeScale={shape.height / 64}
      />
      <Circle
        id={shape.id}
        x={shape.x + shape.width / 2}
        y={shape.y + shape.width / 4}
        radius={shape.width / 4}
        fill={diagramTheme().lineColor}
        stroke={
          selected
            ? darkTheme.palette.secondary.main
            : hovering
            ? darkTheme.palette.primary.main
            : "transparent"
        }
        strokeWidth={1}
        onMouseEnter={() => hoverable && setHovering(true)}
        onMouseLeave={() => hoverable && setHovering(false)}
        onContextMenu={handleContextMenu}
      />
    </>
  );
};

Actor.defaultProps = {
  hoverable: true,
};

export default Actor;
