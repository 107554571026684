import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Circle, Layer, Rect } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import {
  createConnection,
  createShape,
  selectConnections,
  selectDiagramId,
  selectShapes,
  setConnection,
  setShape,
  updateElements,
} from "../../features/diagram/diagramSlice";
import { selectSelection } from "../../features/selection/selectionSlice";
import { diagramTheme } from "../../theme";
import {
  calculateAnchorCoordinates,
  filterNotNull,
  findClosestAnchor,
  generateShape,
  getMouseStagePosition,
  isConnection,
  isShape,
  ITEM_TYPES,
  minSnapDistance,
} from "../../utils/diagramUtils";
import { bounded } from "../../utils/envUtils";
import { snapToGrid } from "./GridLayer";
import omit from "lodash.omit";
import OrthogonalConnection from "../DiagramElements/OrthogonalConnection/OrthogonalConnection";
import SelectableLine from "../DiagramElements/SelectableLine/SelectableLine";
import Actor from "../DiagramElements/Actor/Actor";
import Decision from "../DiagramElements/Decision/Decision";
import SelectableRect from "../DiagramElements/SelectableRect/SelectableRect";
import {
  selectInsertionType,
  stopInsertion,
} from "../../features/insertion/insertionSlice";

const anchorBaseSize = 8;

const generateInsertionNode = (insertionType, insertionElement) => {
  // FIXME: The element json should be stored in the state for easy element creation on mouse click
  if (!insertionElement) return null;
  switch (insertionType) {
    case ITEM_TYPES.RECT:
    case ITEM_TYPES.CONTAINER:
      return <SelectableRect shape={insertionElement} hoverable={false} />;
    case ITEM_TYPES.DECISION:
      return <Decision shape={insertionElement} hoverable={false} />;
    case ITEM_TYPES.ACTOR:
      return <Actor shape={insertionElement} hoverable={false} />;
    case ITEM_TYPES.LINE:
      // FIXME: Connections should work like on figjam, dragging from start to end, with anchor support
      return <SelectableLine connection={insertionElement} hoverable={false} />;
    case ITEM_TYPES.ORTHOGONAL_CONNECTION:
      return (
        <OrthogonalConnection connection={insertionElement} hoverable={false} />
      );
    default:
      return null;
  }
};

const ResizeLayer = ({ stage, scale, editable }) => {
  const dispatch = useDispatch();
  const diagramId = useSelector(selectDiagramId);
  const shapes = useSelector(selectShapes);
  const connections = useSelector(selectConnections);
  const selection = useSelector(selectSelection);
  const [startingElements, setStartingElements] = useState(null);
  const [resizeStart, setResizeStart] = useState(null);
  const [resizeAnchor, setResizeAnchor] = useState(null);
  const [cachedAnchors, setCachedAnchors] = useState({});
  const anchorSize = anchorBaseSize / bounded(scale, 0.5, 2) ?? 1;
  const insertionType = useSelector(selectInsertionType);
  const [insertionPosition, setInsertionPosition] = useState(null);
  const [insertionElement, setInsertionElement] = useState(null);

  useEffect(() => {
    setCachedAnchors(
      Object.values(shapes).flatMap(
        (s) =>
          s.anchors?.map((a) => ({
            shapeId: s.id,
            position: a.position,
            ...calculateAnchorCoordinates(s, a.position),
          })) || []
      )
    );
  }, [shapes]);

  useEffect(() => {
    if (!insertionType) {
      setInsertionPosition(null);
      setInsertionElement(null);
    }
  }, [insertionType]);

  ////////////////////////////////
  // MOUSE DOWN
  ////////////////////////////////
  const handleMouseDown = useCallback(
    (e) => {
      if (insertionType) {
        // TODO: create element
        if (isShape(insertionElement)) {
          dispatch(createShape({ diagramId, shape: insertionElement }));
        } else if (isConnection(insertionElement)) {
          dispatch(
            createConnection({ diagramId, connection: insertionElement })
          );
        }
        dispatch(stopInsertion());
        setInsertionPosition(null);
        setInsertionElement(null);
      } else {
        setStartingElements({
          ...cloneDeep(shapes),
          ...cloneDeep(connections),
        });
        setResizeStart(getMouseStagePosition(e, stage));
        setResizeAnchor(e.target.attrs);
      }
      // FIXME: Display Anchors = true here, should be local to this component
      // if (connections[id]) onResize(true);
    },
    [
      connections,
      diagramId,
      dispatch,
      insertionElement,
      insertionType,
      shapes,
      stage,
    ]
  );

  ////////////////////////////////
  // MOUSE MOVE
  ////////////////////////////////
  const handleMouseMove = useCallback(
    (e) => {
      const position = getMouseStagePosition(e, stage);
      const snappedPosition = {
        x: snapToGrid(position.x),
        y: snapToGrid(position.y),
      };
      if (
        (insertionType && snappedPosition.x !== insertionPosition?.x) ||
        snappedPosition.y !== insertionPosition?.y
      ) {
        setInsertionPosition(snappedPosition);
        let element;
        if (isShape({ type: insertionType })) {
          element = generateShape(insertionType, snappedPosition, true);
        } else if (isConnection({ type: insertionType })) {
          element = generateShape(
            insertionType,
            {
              start: snappedPosition,
              end: {
                x: snappedPosition.x + 32,
                y: snappedPosition.y + 32,
              },
            },
            true
          );
        }
        setInsertionElement(element);
      }
      if (editable) {
        if (resizeStart && e.evt.buttons === 1) {
          const startingElement = startingElements[resizeAnchor.id];
          const moveBy = {
            x: snapToGrid(position.x - resizeStart.x),
            y: snapToGrid(position.y - resizeStart.y),
          };
          if (startingElement && isShape(startingElement)) {
            // Shapes just get resized by x and y
            const shape = shapes[resizeAnchor.id];
            let x, y, width, height;
            const x1 =
              startingElement.x +
              (resizeAnchor.pos.includes("left") ? moveBy.x : 0);
            const y1 =
              startingElement.y +
              (resizeAnchor.pos.includes("top") ? moveBy.y : 0);
            const x2 =
              startingElement.x +
              startingElement.width +
              (resizeAnchor.pos.includes("right") ? moveBy.x : 0);
            const y2 =
              startingElement.y +
              startingElement.height +
              (resizeAnchor.pos.includes("bottom") ? moveBy.y : 0);
            x = Math.min(x1, x2);
            y = Math.min(y1, y2);
            width = Math.abs(x2 - x1);
            height = Math.abs(y2 - y1);
            if (shape.type === ITEM_TYPES.DECISION) {
              width = Math.min(width, height);
              height = width;
            } else if (shape.type === ITEM_TYPES.ACTOR) {
              width = Math.min(width, height);
              height = width * 2;
            }
            if (
              shape.type === ITEM_TYPES.DECISION ||
              shape.type === ITEM_TYPES.ACTOR
            ) {
              if (resizeAnchor.pos.includes("left") && x === x1) x = x2 - width;
              if (resizeAnchor.pos.includes("top") && y === y1) y = y2 - height;
              if (resizeAnchor.pos.includes("right") && x === x2)
                x = x1 - width;
              if (resizeAnchor.pos.includes("bottom") && y === y2)
                y = y1 - height;
            }
            if (width !== shape.width || height !== shape.height)
              dispatch(
                setShape({
                  id: startingElement.id,
                  shape: { x, y, width, height },
                })
              );
          } else if (startingElement && isConnection(startingElement)) {
            // Connections either get their start or end point moved
            // to the mouse position, or to the hovered anchor
            const currentConnection = connections[resizeAnchor.id];
            const newConnection = {};
            const [anchor, distance] = findClosestAnchor(
              cachedAnchors,
              position
            );
            const shouldSnap = distance < minSnapDistance;
            if (resizeAnchor.pos.includes("start")) {
              if (shouldSnap) {
                newConnection.start = omit(anchor, ["shapeId", "position"]);
                newConnection.startAnchor = omit(anchor, ["x", "y"]);
              } else {
                newConnection.start = {
                  x: startingElement.start.x + moveBy.x,
                  y: startingElement.start.y + moveBy.y,
                };
                newConnection.startAnchor = null;
              }
            }
            if (resizeAnchor.pos.includes("end")) {
              if (shouldSnap) {
                newConnection.end = omit(anchor, ["shapeId", "position"]);
                newConnection.endAnchor = omit(anchor, ["x", "y"]);
              } else {
                newConnection.end = {
                  x: startingElement.end.x + moveBy.x,
                  y: startingElement.end.y + moveBy.y,
                };
                newConnection.endAnchor = null;
              }
            }
            if (
              (newConnection.start?.x &&
                newConnection.start?.x !== currentConnection.start.x) ||
              (newConnection.start?.y &&
                newConnection.start?.y !== currentConnection.start.y) ||
              (newConnection.end?.x &&
                newConnection.end?.x !== currentConnection.end.x) ||
              (newConnection.end?.y &&
                newConnection.end?.y !== currentConnection.end.y)
            ) {
              dispatch(
                setConnection({
                  id: startingElement.id,
                  connection: newConnection,
                })
              );
            }
          }
        }
      }
    },
    [
      cachedAnchors,
      connections,
      dispatch,
      editable,
      insertionPosition,
      insertionType,
      resizeAnchor,
      resizeStart,
      shapes,
      stage,
      startingElements,
    ]
  );

  ////////////////////////////////
  // MOUSE UP
  ////////////////////////////////
  const handleMouseUp = useCallback(
    (e) => {
      if (editable && resizeStart && !insertionType) {
        const ids = Object.keys(selection);
        if (ids.length > 0) {
          dispatch(
            updateElements({
              diagramId: diagramId,
              elements: ids.map((id) => shapes[id] || connections[id]),
            })
          );
        }
      }
      setResizeAnchor(null);
      setResizeStart(null);
    },
    [
      connections,
      diagramId,
      dispatch,
      editable,
      insertionType,
      resizeStart,
      selection,
      shapes,
    ]
  );

  const insertionNode = useMemo(
    () => generateInsertionNode(insertionType, insertionElement),
    [insertionElement, insertionType]
  );
  const displayAnchors =
    !!resizeAnchor?.id && isConnection(connections[resizeAnchor.id]);
  return (
    <Layer
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {stage && (!!resizeStart || !!insertionType) && (
        <Rect
          x={-stage.x() / stage.scaleX()}
          y={-stage.y() / stage.scaleY()}
          width={stage.width() / stage.scaleX()}
          height={stage.height() / stage.scaleY()}
        />
      )}
      {insertionNode}
      {displayAnchors &&
        Object.values(shapes)
          .filter((s) => s.anchors)
          .flatMap((s) =>
            s.anchors
              .map((a) => {
                const coords = calculateAnchorCoordinates(s, a.position);
                return !coords ? null : (
                  <Circle
                    key={`${s.id}-snap-anchor-${a.position}`}
                    x={coords.x}
                    y={coords.y}
                    radius={1}
                    fill={diagramTheme().anchorFill}
                    stroke={diagramTheme().anchorStroke}
                    strokeWidth={1}
                    listening={false}
                  />
                );
              })
              .filter(filterNotNull)
          )}
      {editable &&
        Object.values(connections).map((connection) => (
          <React.Fragment key={connection.id}>
            <Circle
              id={connection.id}
              pos={"start"}
              x={connection.start.x}
              y={connection.start.y}
              radius={
                editable && !!selection[connection.id] ? anchorSize / 1.75 : 0
              }
              stroke={diagramTheme().anchorStroke}
              fill={diagramTheme().anchorFill}
              strokeWidth={1 / scale}
            />
            <Circle
              id={connection.id}
              pos={"end"}
              x={connection.end.x}
              y={connection.end.y}
              radius={
                editable && !!selection[connection.id] ? anchorSize / 1.75 : 0
              }
              stroke={diagramTheme().anchorStroke}
              fill={diagramTheme().anchorFill}
              strokeWidth={1 / scale}
            />
          </React.Fragment>
        ))}
      {editable &&
        Object.values(shapes).map((shape) => (
          <React.Fragment key={shape.id}>
            <Rect
              id={shape.id}
              pos={"top-left"}
              x={shape.x - (shape.radius ?? 0) - anchorSize / 2}
              y={shape.y + (shape.radius ?? 0) - anchorSize / 2}
              width={editable && !!selection[shape.id] ? anchorSize : 0}
              height={editable && !!selection[shape.id] ? anchorSize : 0}
              fill={diagramTheme().anchorFill}
              stroke={diagramTheme().anchorStroke}
              strokeWidth={1 / scale}
            />
            <Rect
              id={shape.id}
              pos={"top-right"}
              x={
                shape.x +
                (shape.width ?? 0) +
                (shape.radius ?? 0) -
                anchorSize / 2
              }
              y={shape.y + (shape.radius ?? 0) - anchorSize / 2}
              width={editable && !!selection[shape.id] ? anchorSize : 0}
              height={editable && !!selection[shape.id] ? anchorSize : 0}
              fill={diagramTheme().anchorFill}
              stroke={diagramTheme().anchorStroke}
              strokeWidth={1 / scale}
            />
            <Rect
              id={shape.id}
              pos={"bottom-right"}
              x={
                shape.x +
                (shape.width ?? 0) -
                (shape.radius ?? 0) -
                anchorSize / 2
              }
              y={
                shape.y +
                (shape.height ?? 0) -
                (shape.radius ?? 0) -
                anchorSize / 2
              }
              width={editable && !!selection[shape.id] ? anchorSize : 0}
              height={editable && !!selection[shape.id] ? anchorSize : 0}
              fill={diagramTheme().anchorFill}
              stroke={diagramTheme().anchorStroke}
              strokeWidth={1 / scale}
            />
            <Rect
              id={shape.id}
              pos={"bottom-left"}
              x={shape.x + (shape.radius ?? 0) - anchorSize / 2}
              y={
                shape.y +
                (shape.height ?? 0) -
                (shape.radius ?? 0) -
                anchorSize / 2
              }
              width={editable && !!selection[shape.id] ? anchorSize : 0}
              height={editable && !!selection[shape.id] ? anchorSize : 0}
              fill={diagramTheme().anchorFill}
              stroke={diagramTheme().anchorStroke}
              strokeWidth={1 / scale}
            />
          </React.Fragment>
        ))}
    </Layer>
  );
};

export default ResizeLayer;
