import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selection: {},
};

export const slice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    resetSelection: (state) => {
      state.selection = {};
    },
    addToSelection: (state, { payload: ids }) => {
      ids.forEach((id) => (state.selection[id] = id));
    },
    removeFromSelection: (state, { payload: ids }) => {
      ids.forEach((id) => delete state.selection[id]);
    },
  },
});

export const { resetSelection, addToSelection, removeFromSelection } =
  slice.actions;

export const selectSelection = (state) => state.selection.selection;

export default slice.reducer;
