import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import TopMenu from "../components/TopMenu/TopMenu";
import LeftMenu from "../components/LeftMenu/LeftMenu";
import Diagram from "../components/Diagram/Diagram";
import { useLoadDiagram } from "../hooks/useDiagram";
import { useSelector } from "react-redux";
import {
  selectDiagram,
  selectDiagramError,
  selectShapesError,
} from "../features/diagram/diagramSlice";
import { diagramTheme, getStoredTheme } from "../theme";
import { useForceUpdate } from "../hooks/forceUpdate";
import { isLoading } from "../features/global/globalSlice";
import DiagramSkeleton from "../components/DiagramSkeleton/DiagramSkeleton";
import { selectUser } from "../features/authentication/authenticationSlice";

const DiagramPage = ({ readonly }) => {
  const params = useParams();
  const forceUpdate = useForceUpdate();
  useLoadDiagram(params.diagramId);
  const user = useSelector(selectUser);
  const diagram = useSelector(selectDiagram);
  const loading = useSelector(isLoading);
  const diagramError = useSelector(selectDiagramError);
  const shapesError = useSelector(selectShapesError);
  const [darkTheme, setDarkTheme] = useState(null);
  const editable =
    !readonly && (loading || diagram?.public || diagram?.owner === user?.uid);
  const handleSwitchTheme = useCallback(() => {
    setDarkTheme((theme) => !theme);
  }, []);
  useEffect(() => {
    setDarkTheme(getStoredTheme());
  }, []);
  useEffect(() => {
    if (diagram?.title) document.title = `anydiagram.io - ${diagram.title}`;
  }, [diagram]);
  useEffect(() => {
    if (darkTheme !== null && getStoredTheme() !== darkTheme) {
      localStorage.setItem("darkTheme", darkTheme);
      forceUpdate();
    }
  }, [darkTheme, forceUpdate]);
  if (diagramError) {
    return <Typography>{JSON.stringify(diagramError)}</Typography>;
  }
  if (shapesError) {
    return <Typography>{JSON.stringify(shapesError)}</Typography>;
  }
  return (
    <>
      <TopMenu
        darkTheme={darkTheme}
        handleSwitchTheme={handleSwitchTheme}
        editable={editable}
      />
      <Box display="flex">
        {editable && <LeftMenu />}
        <Box
          flexGrow="1"
          height="calc(100vh - 48px)"
          backgroundColor={diagramTheme().backgroundColor}
        >
          {loading && <DiagramSkeleton />}
          {!loading && <Diagram editable={editable} />}
        </Box>
      </Box>
    </>
  );
};

export default DiagramPage;
