import { Box } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { Stage } from "react-konva";
import { Provider, useDispatch, useSelector } from "react-redux";
import isEqual from "lodash.isequal";
import {
  selectDiagram,
  selectDiagramId,
  updateDiagram,
} from "../../features/diagram/diagramSlice";
import { useKeyboardShortcuts } from "../../hooks/useKeyboardShortcuts";
import { useUpdatableStageSize as useRefWithSize } from "../../hooks/useUpdatableStageSize";
import ResizeLayer from "./ResizeLayer";
import ElementsLayer from "./ElementsLayer";
import GridLayer from "./GridLayer";
import { store } from "../../features/store";
import { exactDistance, getCenter } from "../../utils/geometryUtils";
import SelectionLayer from "./SelectionLayer";
import { getMouseStagePosition } from "../../utils/diagramUtils";
import ShapeTextEdit from "../ShapeTextEdit/ShapeTextEdit";
import ToolPoppover from "../ToolPoppover/ToolPoppover";
import { useWindowContextMenuListener } from "../../hooks/useWindowContextMenuListener";
import { selectEditedElementId } from "../../features/editing/editingSlice";
import DebugLayer from "./DebugLayer";

export const refreshTime = 1000 / 30;

const Diagram = ({ editable }) => {
  // Redux Data
  const dispatch = useDispatch();
  const diagramId = useSelector(selectDiagramId);
  const diagram = useSelector(selectDiagram);
  const editedElementId = useSelector(selectEditedElementId);

  // Local Data
  const [stageParentRef, width, height] = useRefWithSize();
  const stageRef = useRef();
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [hoveredShapeId, setHoveredShapeId] = useState(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [debugPosition, setDebugPosition] = useState(null);
  const [scale, setScale] = useState(1);
  const [lastTouch, setLastTouch] = useState(null);
  const [lastCenter, setLastCenter] = useState(null);
  const [lastDist, setLastDist] = useState(null);

  useKeyboardShortcuts(diagramId, editable);

  const rightClickPreventer = useCallback((e) => {
    e.preventDefault();
    setHoveredShapeId(null);
  }, []);
  useWindowContextMenuListener(rightClickPreventer);

  const reorderElement = useCallback(
    (shapeId, offset) => {
      const index = diagram.orderedElements.indexOf(shapeId);
      let newOrder;
      if (offset < 1) {
        newOrder = [
          diagram.orderedElements[index],
          ...diagram.orderedElements.slice(0, Math.max(0, index)),
          ...diagram.orderedElements.slice(
            Math.min(diagram.orderedElements.length, index + 1)
          ),
        ];
      } else if (offset > 1) {
        newOrder = [
          ...diagram.orderedElements.slice(0, index),
          ...diagram.orderedElements.slice(
            Math.min(diagram.orderedElements.length, index + 1)
          ),
          diagram.orderedElements[index],
        ];
      } else if (offset < 0) {
        newOrder = [
          ...diagram.orderedElements.slice(0, Math.max(0, index - 1)),
          diagram.orderedElements[index],
          ...(index > 0 ? [diagram.orderedElements[index - 1]] : []),
          ...diagram.orderedElements.slice(
            Math.min(diagram.orderedElements.length, index + 1)
          ),
        ];
      } else if (offset > 0) {
        newOrder = [
          ...diagram.orderedElements.slice(0, index),
          ...(index < diagram.orderedElements.length - 1
            ? [diagram.orderedElements[index + 1]]
            : []),
          diagram.orderedElements[index],
          ...diagram.orderedElements.slice(
            Math.min(diagram.orderedElements.length, index + 2)
          ),
        ];
      }
      if (!isEqual(newOrder, diagram.orderedElements)) {
        dispatch(
          updateDiagram({ diagramId, diagram: { orderedElements: newOrder } })
        );
      }
    },
    [diagram, diagramId, dispatch]
  );
  const handleWheel = useCallback(
    (e) => {
      e.evt.preventDefault();
      if (e.evt.ctrlKey) {
        var scaleBy = 1.03;
        var pointer = stageRef.current.getPointerPosition();
        var mousePointTo = {
          x: (pointer.x - stageRef.current.x()) / scale,
          y: (pointer.y - stageRef.current.y()) / scale,
        };
        let direction = e.evt.deltaY > 0 ? 1 : -1;
        // TODO: see how we handle mouse wheel as opposed to trackpad
        if (e.evt.ctrlKey) {
          direction = -direction;
        }
        var newScale = direction > 0 ? scale * scaleBy : scale / scaleBy;
        setScale(newScale);
        setX(pointer.x - mousePointTo.x * newScale);
        setY(pointer.y - mousePointTo.y * newScale);
      } else {
        setX((x) => x - e.evt.deltaX);
        setY((y) => y - e.evt.deltaY);
      }
    },
    [scale]
  );
  const handleTouchMove = useCallback(
    (e) => {
      e.evt.preventDefault();
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];
      if (touch1 && touch2) {
        const p1 = { x: touch1.clientX, y: touch1.clientY };
        const p2 = { x: touch2.clientX, y: touch2.clientY };
        const newCenter = getCenter(p1, p2);
        const dist = exactDistance(p1, p2);
        if (lastCenter && lastDist) {
          // local coordinates of center point
          const pointTo = {
            x: (newCenter.x - stageRef.current.x()) / stageRef.current.scaleX(),
            y: (newCenter.y - stageRef.current.y()) / stageRef.current.scaleX(),
          };
          const scale = stageRef.current.scaleX() * (dist / lastDist);
          stageRef.current.scaleX(scale);
          stageRef.current.scaleY(scale);
          // calculate new position of the stage
          const dx = newCenter.x - lastCenter.x;
          const dy = newCenter.y - lastCenter.y;
          const newPos = {
            x: newCenter.x - pointTo.x * scale + dx,
            y: newCenter.y - pointTo.y * scale + dy,
          };
          stageRef.current.position(newPos);
        }
        setLastCenter(newCenter);
        setLastDist(dist);
      } else if (touch1) {
        const p1 = { x: touch1.clientX, y: touch1.clientY };
        if (lastTouch) {
          stageRef.current.position({
            x: stageRef.current.x() + p1.x - lastTouch.x,
            y: stageRef.current.y() + p1.y - lastTouch.y,
          });
        }
        setLastTouch(p1);
      }
    },
    [lastTouch, lastCenter, lastDist]
  );
  const handleTouchEnd = useCallback(() => {
    setLastTouch(null);
    setLastCenter(null);
    setLastDist(0);
  }, []);
  const handleDebug = useCallback(
    (e) => {
      if (stageRef.current)
        setDebugPosition(getMouseStagePosition(e, stageRef.current));
    },
    [stageRef]
  );
  return (
    <Box
      width="100%"
      maxWidth="100%"
      height="100%"
      maxHeight="100%"
      id="stage-parent"
      ref={stageParentRef}
      sx={{ overflow: "hidden" }}
    >
      <Stage
        ref={stageRef}
        x={x}
        y={y}
        scaleX={scale}
        scaleY={scale}
        width={width}
        height={height}
        onWheel={handleWheel}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseMove={handleDebug}
      >
        <Provider store={store}>
          <SelectionLayer stage={stageRef.current} />
          <GridLayer
            x={-x * (1 / scale)}
            y={-y * (1 / scale)}
            width={width * (1 / scale)}
            height={height * (1 / scale)}
            editable={editable}
          />
          <ElementsLayer
            stage={stageRef?.current}
            editable={editable}
            onOpenTools={(shapeId, anchorPosition) => {
              setAnchorPosition(anchorPosition);
              setHoveredShapeId(shapeId);
            }}
          />
          <ResizeLayer
            stage={stageRef.current}
            scale={scale}
            editable={editable && !editedElementId}
          />
        </Provider>
        <DebugLayer stage={stageRef?.current} debugPosition={debugPosition} />
      </Stage>
      {editedElementId && <ShapeTextEdit stage={stageRef.current} />}
      <ToolPoppover
        stageRef={stageRef.current}
        open={!!hoveredShapeId}
        onClose={() => setHoveredShapeId(null)}
        anchorPosition={anchorPosition}
        shapeId={hoveredShapeId}
        moveShapeIndex={reorderElement}
      />
    </Box>
  );
};

export default Diagram;
