import { useEffect } from "react";

export const useWindowContextMenuListener = (callback, listening = true) => {
  useEffect(() => {
    if (listening) {
      window.addEventListener("contextmenu", callback);
      return () => {
        window.removeEventListener("contextmenu", callback);
      };
    }
  }, [callback, listening]);
};
