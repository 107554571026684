import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearConnection,
  clearShape,
  createConnection,
  createShape,
  deleteConnection,
  deleteShape,
  selectConnections,
  selectShapes,
} from "../features/diagram/diagramSlice";
import { startEdit } from "../features/editing/editingSlice";
import { stopInsertion } from "../features/insertion/insertionSlice";
import {
  addToSelection,
  resetSelection,
  selectSelection,
} from "../features/selection/selectionSlice";
import { generateShape, isShape } from "../utils/diagramUtils";

const isCharacterKeyPress = (evt) => {
  if (typeof evt.which == "undefined") {
    // This is IE, which only fires keypress events for printable keys
    return true;
  } else if (typeof evt.which == "number" && evt.which > 0) {
    // In other browsers except old versions of WebKit, evt.which is
    // only greater than zero if the keypress is a printable key.
    // We need to filter out backspace and ctrl/alt/meta key combinations
    return !evt.ctrlKey && !evt.metaKey && !evt.altKey && evt.which !== 8;
  }
  return false;
};

export const useKeyboardShortcuts = (diagramId, editable) => {
  const dispatch = useDispatch();
  const shapes = useSelector(selectShapes);
  const connections = useSelector(selectConnections);
  const selection = useSelector(selectSelection);
  const handleKeyDown = useCallback(
    async (e) => {
      // Keyboard Shortcuts that require a selected shape
      if (editable && Object.keys(selection).length > 0) {
        if (e.key === "Backspace" || e.key === "Delete") {
          if (e.target.nodeName !== "TEXTAREA") {
            e.preventDefault();
            Object.keys(selection)
              .map((id) => shapes[id] || connections[id])
              .filter((element) => !!element)
              .forEach((element) => {
                dispatch(
                  isShape(element)
                    ? clearShape(element.id)
                    : clearConnection(element.id)
                );
                dispatch(
                  isShape(element)
                    ? deleteShape({ diagramId, shapeId: element.id })
                    : deleteConnection({
                        diagramId,
                        connectionId: element.id,
                      })
                );
              });
            dispatch(resetSelection());
          }
        } else if (
          (e.ctrlKey || e.metaKey) &&
          (e.key === "d" || e.key === "v")
        ) {
          e.preventDefault();
          // FIXME: When copy pasting a shape and its anchored connection,
          // the duplicated connection should be anchored to the duplicated shape
          dispatch(resetSelection());
          Object.keys(selection)
            .map((id) => shapes[id] || connections[id])
            .filter((element) => !!element)
            .forEach((element) => {
              dispatch(
                isShape(element)
                  ? createShape({
                      diagramId,
                      shape: generateShape(element.type, element),
                    })
                  : createConnection({
                      diagramId,
                      connection: generateShape(element.type, element),
                    })
              );
            });
        } else if (
          Object.keys(selection).length === 1 &&
          isCharacterKeyPress(e)
        ) {
          dispatch(startEdit(Object.keys(selection)[0]));
        }
      }
      // Keyboard Shortcuts that do not require a selected shape
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
      } else if ((e.ctrlKey || e.metaKey) && e.key === "a") {
        e.preventDefault();
        dispatch(resetSelection());
        dispatch(
          addToSelection([...Object.keys(shapes), ...Object.keys(connections)])
        );
      }
      if (e.key === "Escape") {
        dispatch(stopInsertion());
      }
    },
    [editable, selection, dispatch, shapes, connections, diagramId]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);
};
