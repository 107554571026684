import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseui from "firebaseui";
import { isLocal } from "../utils/envUtils";

const prodFirebaseConfig = {
  apiKey: "AIzaSyBiEiClBtvUIA9PfSDzCM1troLgB6ExDbk",
  authDomain: "anydiagram.firebaseapp.com",
  projectId: "anydiagram",
  storageBucket: "anydiagram.appspot.com",
  messagingSenderId: "156624293449",
  appId: "1:156624293449:web:f6a8ad3b40c4b019257998",
  measurementId: "G-J866ELCV9Z",
};

const devFirebaseConfig = {
  apiKey: "AIzaSyD3w-BdnZT2VlhBxr1NAXYngy2Qc1vzcKg",
  authDomain: "anydiagram-dev.firebaseapp.com",
  projectId: "anydiagram-dev",
  storageBucket: "anydiagram-dev.appspot.com",
  messagingSenderId: "101476227971",
  appId: "1:101476227971:web:038de68b8b3fba9aaf74b6",
  measurementId: "G-WYQXBVPTX9",
};

// TODO: Add connection to dev from dev hosting, same for staging

export const app = initializeApp(
  isLocal() ? devFirebaseConfig : prodFirebaseConfig
);
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
// export const auth = isLocal() ? getAuth() : getAuth(app);
// if (isLocal()) connectAuthEmulator(auth, "http://localhost:9099");

export const db = getFirestore(app);
// export const db = isLocal() ? getFirestore() : getFirestore(app);
// if (isLocal()) connectFirestoreEmulator(db, "localhost", 8080);

export const loginUi = new firebaseui.auth.AuthUI(auth);
