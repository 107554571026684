import { TextareaAutosize } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDiagramId,
  setShape,
  updateElements,
} from "../../features/diagram/diagramSlice";
import {
  selectEditedElement,
  stopEdit,
} from "../../features/editing/editingSlice";
import { diagramTheme } from "../../theme";
import { SHAPE_TEXT_CONSTANTS } from "../DiagramElements/ShapeText/ShapeText";
import { leftMenuWidth } from "../LeftMenu/LeftMenu";

const ShapeTextEdit = ({ stage }) => {
  const dispatch = useDispatch();
  const diagramId = useSelector(selectDiagramId);
  const editedElement = useSelector(selectEditedElement);
  // This is to prevent a bug where handleOutsideClick is triggered after the double click that displays this component
  // const init = useRef(false);
  const textareaRef = useRef(false);
  // FIXME: Store the text locally instead of redux? To avoid submitting if unchanged
  const submit = useCallback(
    (text) => {
      if (editedElement) {
        dispatch(
          updateElements({
            diagramId,
            elements: [
              {
                ...editedElement,
                text,
              },
            ],
          })
        );
        dispatch(stopEdit());
      }
    },
    [diagramId, dispatch, editedElement]
  );
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current?.setSelectionRange(
        textareaRef.current?.value?.length,
        textareaRef.current?.value?.length
      );
      textareaRef.current.focus();
    }
  }, []);
  if (!editedElement) return null;
  return (
    <TextareaAutosize
      ref={textareaRef}
      value={editedElement.text}
      onChange={(e) => {
        dispatch(
          setShape({
            id: editedElement.id,
            shape: {
              ...editedElement,
              text: e.target.value,
            },
          })
        );
      }}
      onBlur={(e) => {
        submit(e.target.value);
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.keyCode === 13 && !e.shiftKey) {
          submit(e.target.value);
        }
      }}
      style={{
        display: "block",
        visibility: editedElement ? "visible" : "hidden",
        fontSize: `${12}px`,
        border: "none",
        padding: "0px",
        margin: "0px",
        overflow: "hidden",
        background: "none",
        outline: "none",
        resize: "none",
        whiteSpace: "pre-wrap",
        width: `${
          editedElement.width - 2 * SHAPE_TEXT_CONSTANTS.textHorizontalPadding
        }px`,
        position: "absolute",
        transformOrigin: "left",
        top: `${
          stage.absolutePosition().y +
          (editedElement.y + editedElement.height / 2 - 0.2) * stage.scaleX() +
          48
        }px`,
        left: `${
          stage?.absolutePosition().x +
          editedElement.x * stage.scaleX() +
          SHAPE_TEXT_CONSTANTS.textHorizontalPadding +
          leftMenuWidth
        }px`,
        transform: `translateY(-50%) scale(${stage.scaleX()})`,
        lineHeight: SHAPE_TEXT_CONSTANTS.lineHeight,
        fontFamily: SHAPE_TEXT_CONSTANTS.fontFamily,
        textAlign: SHAPE_TEXT_CONSTANTS.align,
        color: diagramTheme().textColor,
      }}
    />
  );
};

export default ShapeTextEdit;
