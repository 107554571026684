import { useCallback, useEffect, useState } from "react";
import { calculateOrthogonalPoints } from "../../../utils/diagramUtils";
import BaseLine from "../../BaseLine/BaseLine";

const OrthogonalConnection = ({
  connection,
  hoverable,
  selected,
  onOpenTools,
}) => {
  const [points, setPoints] = useState([]);
  const [hovering, setHovering] = useState(false);
  const handleContextMenu = useCallback(
    (e) => {
      e.evt.preventDefault();
      e.evt.stopPropagation();
      onOpenTools(connection.id, { left: e.evt.clientX, top: e.evt.clientY });
    },
    [onOpenTools, connection.id]
  );
  useEffect(() => {
    setPoints(calculateOrthogonalPoints(connection));
  }, [connection]);
  return (
    <BaseLine
      id={connection.id}
      points={points}
      startArrow={!!connection.startArrow}
      endArrow={!!connection.endArrow}
      selected={selected}
      hovering={hovering}
      setHovering={(bool) => hoverable && setHovering(bool)}
      handleContextMenu={handleContextMenu}
    />
  );
};

OrthogonalConnection.defaultProps = {
  hoverable: true,
};

export default OrthogonalConnection;
