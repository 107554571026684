import { createTheme, responsiveFontSizes } from "@mui/material";

const palette = {
  mode: "dark",
  type: "dark",
  primary: {
    main: "#c7c7c7",
  },
  secondary: {
    main: "#ff7a00",
  },
  info: {
    main: "#2196f3",
  },
  error: {
    main: "#c9372c",
  },
  background: {
    paper: "#424242",
  },
  divider: "rgba(255,255,255,0.02)",
};

const typography = {
  fontFamily: '"Nunito", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: "2.5rem",
  },
  h2: {
    fontSize: "2rem",
  },
  h3: {
    fontSize: "1.8rem",
    fontWeight: 300,
  },
};

const overrides = {
  MuiAppBar: {
    colorInherit: {
      backgroundColor: "#689f38",
      color: "#fff",
    },
  },
};

const components = {
  MuiToggleButton: {
    styleOverrides: {
      sizeSmall: {
        padding: "5px",
        fontSize: "20px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        color: "white",
        backgroundColor: "#373c44",
      },
    },
    defaultProps: {
      size: "small",
    },
  },
  MuiAppBar: {
    defaultProps: {
      color: "default",
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiList: {
    defaultProps: {
      dense: true,
    },
  },
  MuiMenuItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiTable: {
    defaultProps: {
      size: "small",
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      size: "small",
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: "small",
    },
  },
  MuiFab: {
    defaultProps: {
      size: "small",
    },
  },
  MuiFormControl: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
  },
  defaultProps: {
    MuiFormHelperText: {
      margin: "dense",
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: "small",
    },
  },
  MuiInputBase: {
    defaultProps: {
      margin: "dense",
    },
  },
  MuiInputLabel: {
    defaultProps: {
      margin: "dense",
    },
  },
  MuiRadio: {
    defaultProps: {
      size: "small",
    },
  },
  MuiSwitch: {
    defaultProps: {
      size: "small",
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },
  },
};

export const darkTheme = responsiveFontSizes(
  createTheme({
    palette,
    typography,
    overrides,
    components,
    // props,
  })
);

export const getStoredTheme = () => {
  const data = localStorage?.getItem("darkTheme");
  if (data) return JSON.parse(data) ?? true;
  return true;
};
export const setStoredTheme = (theme) => {
  if (theme !== null && getStoredTheme() !== theme) {
    localStorage?.setItem("darkTheme", theme);
  }
};

export const switchStoredTheme = () => {
  setStoredTheme(!getStoredTheme());
};

export const darkDiagramTheme = {
  backgroundColor: darkTheme.palette.background.paper,
  gridColor: darkTheme.palette.divider,
  shapeColor: "#222222",
  containerColor: "#FFFFFF10",
  textColor: "#C7C7C7",
  lineColor: "#808080",
  anchorFill: "#FFFFFF",
  anchorStroke: "#505050",
};
export const lightDiagramTheme = {
  backgroundColor: "#FFFFFF",
  gridColor: "#F3F3F3",
  shapeColor: "#DFDFDF",
  containerColor: "#00000009",
  textColor: "#111111",
  lineColor: "#505050",
  anchorFill: "#FFFFFF",
  anchorStroke: "#505050",
};

export const diagramTheme = () =>
  getStoredTheme() ? darkDiagramTheme : lightDiagramTheme;
