import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: null,
};

export const slice = createSlice({
  name: "insertion",
  initialState,
  reducers: {
    startInsertion: (state, { payload: type }) => {
      state.type = type;
    },
    stopInsertion: (state) => {
      state.type = null;
    },
  },
});

export const { startInsertion, stopInsertion } = slice.actions;

export const selectInsertionType = (state) => state.insertion.type;

export default slice.reducer;
