/* eslint-disable no-restricted-globals */
export const isLocal = () =>
  location.hostname === "localhost" ||
  location.hostname === "127.0.0.1" ||
  location.hostname.includes("192.168");

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
export const isFirefox =
  navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
export const isEdge = document.documentMode || /Edge/.test(navigator.userAgent);

export const getRedirectPath = () =>
  `${window.location.pathname ?? ""}
  ${window.location.search ?? ""}
  ${window.location.hash ?? ""}`;

export const bounded = (num, min, max) => Math.min(Math.max(num, min), max);
