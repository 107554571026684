import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GoogleAuthProvider, signInAnonymously, signOut } from "firebase/auth";
import { auth, loginUi } from "../../config/firebase";
import { getRedirectPath } from "../../utils/envUtils";

const initialState = {
  user: null,
  userLoaded: false,
  dialogOpen: false,
};

export const signinAnonymously = createAsyncThunk(
  "authentication/signin",
  async (_, { dispatch, getState }) => {
    signInAnonymously(auth);
  }
);

export const signin = createAsyncThunk(
  "authentication/signin",
  async (_, { dispatch, getState }) => {
    loginUi.start("#firebaseui-auth-container", {
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          dispatch(closeSigninDialog());
          return false;
        },
        uiShown: () => {
          document.getElementById("loader").style.display = "none";
        },
      },
      signInFlow: "popup",
      signInSuccessUrl: getRedirectPath(),
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: "select_account",
          },
        },
      ],
      tosUrl: "<your-tos-url>",
      privacyPolicyUrl: "<your-privacy-policy-url>",
    });
  }
);

export const signout = createAsyncThunk(
  "authentication/signout",
  async (_, { dispatch, getState }) => {
    signOut(auth);
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUser: (state, { payload: user }) => {
      state.user = user;
      state.userLoaded = true;
    },
    openSigninDialog: (state) => {
      state.dialogOpen = true;
    },
    closeSigninDialog: (state) => {
      state.dialogOpen = false;
    },
  },
});

export const { setUser, openSigninDialog, closeSigninDialog } =
  authenticationSlice.actions;

export const selectUser = (state) => state.authentication.user;
export const selectUserLoaded = (state) => state.authentication.userLoaded;
export const selectSignInDialogOpen = (state) =>
  state.authentication.dialogOpen;

export default authenticationSlice.reducer;
