import { Layer, Line, Rect } from "react-konva";
import { diagramTheme } from "../../theme";

const GridLayer = ({ x, y, width, height, editable }) => {
  const offsetX = x - (x % gridSize);
  const offsetY = y - (y % gridSize);
  return (
    <Layer listening={false}>
      <Rect
        x={x}
        y={y}
        fill={diagramTheme().backgroundColor}
        width={width}
        height={height}
      />
      {editable &&
        [...Array(Math.ceil(height / gridSize + 1))].map((_, i) => (
          <Line
            key={i}
            points={[
              x,
              i * gridSize + offsetY,
              x + width,
              i * gridSize + offsetY,
            ]}
            stroke={diagramTheme().gridColor}
            strokeWidth={1}
          />
        ))}
      {editable &&
        [...Array(Math.ceil(width / gridSize + 1))].map((_, i) => (
          <Line
            key={i}
            points={[
              i * gridSize + offsetX,
              y,
              i * gridSize + offsetX,
              y + height,
            ]}
            stroke={diagramTheme().gridColor}
            strokeWidth={1}
          />
        ))}
    </Layer>
  );
};

export default GridLayer;
export const gridSize = 8;
export const snapToGrid = (x) => Math.round(x / gridSize) * gridSize;
export const snapPointToGrid = ({ x, y }) => ({
  x: snapToGrid(x),
  y: snapToGrid(y),
});
