import React, { useCallback } from "react";
import { addDoc, collection } from "firebase/firestore";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import TopMenu from "../components/TopMenu/TopMenu";
import { useDispatch, useSelector } from "react-redux";
import { setDiagramLoading } from "../features/diagram/diagramSlice";
import { selectUser } from "../features/authentication/authenticationSlice";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const handleNewDiagram = useCallback(async () => {
    dispatch(setDiagramLoading(true));
    const ref = await addDoc(collection(db, "diagrams"), {
      title: null,
      owner: user?.uid,
      createdAt: new Date().getTime(),
      readable: true,
      // public should be false by default if the user is logged in, and can only be made true by the owner / creator
      public: true,
    });
    navigate(`/diagrams/${ref.id}`);
  }, [dispatch, navigate, user]);
  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#EEEEEE",
        color: "#111111",
        minHeight: `calc(100vh)`,
      }}
    >
      <TopMenu />
      <Box>
        <Container maxWidth="md">
          <Box display="flex" flexDirection="column">
            <Box mt="100px">
              <Typography variant="h1">
                Draw and share simple diagrams, effortlessly.
              </Typography>
            </Box>
            <Box mt="40px">
              <Typography variant="h3">
                Need to draw an architecture during a meeting?
              </Typography>
              <Typography variant="h3">
                Sharing a whiteboard for a system design interview?
              </Typography>
              <Typography variant="h3">
                Designing your latest awesome feature?
              </Typography>
            </Box>
            <Box mt="40px">
              <Typography variant="h2">We've got you covered.</Typography>
            </Box>
            <Box
              mt="40px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              sx={{
                backgroundImage:
                  "url(https://firebasestorage.googleapis.com/v0/b/anydiagram-dev.appspot.com/o/public%2Farrows_centered.png?alt=media)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              <Button variant="contained" onClick={handleNewDiagram}>
                Start drawing
              </Button>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center" mt="40px">
              <Box flex="1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/anydiagram-dev.appspot.com/o/public%2Fsimple_flowchart.png?alt=media"
                  alt="Flowchart Example"
                  width="100%"
                />
              </Box>
              <Box flex="1" alignSelf="center">
                <Typography variant="h3">
                  Flowcharts that people understand in just a few clicks
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" mt="40px">
              <Box flex="1" alignSelf="center">
                <Typography variant="h3">
                  Simple deployment diagrams that go straight to the point
                </Typography>
              </Box>
              <Box flex="1">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/anydiagram-dev.appspot.com/o/public%2Fsimple_deployment_diagram.png?alt=media"
                  alt="Deployment Diagram Example"
                  width="100%"
                />
              </Box>
            </Stack>
            <Box mt="80px">
              <Typography variant="h3">Frequently Asked Questions</Typography>
            </Box>
            <Box textAlign="left">
              <Box mt="40px">
                <Typography variant="h6">
                  Can I embed diagrams on Confluence pages?
                </Typography>
              </Box>
              <Box mt="16px">
                <Typography variant="body1">
                  Yes! You can use the Confluence iframe macro to embed any
                  diagram into a Confluence page. Just copy your embed link
                  (e.g.{" "}
                  <a href="https://anydiagram.io/embeds/readonly">
                    https://anydiagram.io/embeds/readonly
                  </a>
                  ), and you're good to go! Embeds are by default read-only, but
                  you can also create an embed that can be modified straight
                  from your confluence page by providing the diagram link (e.g.{" "}
                  <a href="https://anydiagram.io/diagrams/public">
                    https://anydiagram.io/diagrams/public
                  </a>
                  ).
                </Typography>
              </Box>
              <Box mt="40px">
                <Typography variant="h6">
                  Can I have multiple users collaborate on a diagram?
                </Typography>
              </Box>
              <Box mt="16px">
                <Typography variant="body1">
                  Absolutely! This is one of our most loved features. Multiple
                  users can connect simultaneously to a diagram and edit it
                  together. You will see each others' modification in real time.
                </Typography>
              </Box>
            </Box>
            <Box mt="80px" />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
