export const manhattanDistance = (p1, p2) =>
  Math.abs(p1.x - p2.x) + Math.abs(p1.y - p2.y);

export const exactDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

export const getCenter = (p1, p2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

export const rectangleIntersectsRectangle = (
  r1x1,
  r1y1,
  r1x2,
  r1y2,
  r2x1,
  r2y1,
  r2x2,
  r2y2
) => {
  if (r1x1 === r1x2 || r1y1 === r1y2 || r2x1 === r2x2 || r2y1 === r2y2)
    return false;
  if (r1x1 > r2x2 || r2x1 > r1x2) return false;
  if (r1y1 > r2y2 || r2y1 > r1y2) return false;
  return true;
};

// returns true if the line from (a,b)->(c,d) intersects with (p,q)->(r,s)
export const lineIntersectsLine = (
  l1x1,
  l1y1,
  l1x2,
  l1y2,
  l2x1,
  l2y1,
  l2x2,
  l2y2
) => {
  var det, gamma, lambda;
  det = (l1x2 - l1x1) * (l2y2 - l2y1) - (l2x2 - l2x1) * (l1y2 - l1y1);
  if (det === 0) {
    return false;
  } else {
    lambda =
      ((l2y2 - l2y1) * (l2x2 - l1x1) + (l2x1 - l2x2) * (l2y2 - l1y1)) / det;
    gamma =
      ((l1y1 - l1y2) * (l2x2 - l1x1) + (l1x2 - l1x1) * (l2y2 - l1y1)) / det;
    return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
  }
};

export const pointInRectangle = (px, py, rx1, ry1, rx2, ry2) => {
  return px >= rx1 && px <= rx2 && py >= ry1 && py <= ry2;
};

export const lineIntersectsRectangle = (
  lx1,
  ly1,
  lx2,
  ly2,
  rx1,
  ry1,
  rx2,
  ry2
) => {
  return (
    lineIntersectsLine(lx1, ly1, lx2, ly2, rx1, ry1, rx2, ry1) ||
    lineIntersectsLine(lx1, ly1, lx2, ly2, rx2, ry1, rx2, ry2) ||
    lineIntersectsLine(lx1, ly1, lx2, ly2, rx2, ry2, rx1, ry2) ||
    lineIntersectsLine(lx1, ly1, lx2, ly2, rx1, ry2, rx1, ry1) ||
    pointInRectangle(
      lx1,
      ly1,
      Math.min(rx1, rx2),
      Math.min(ry1, ry2),
      Math.max(rx1, rx2),
      Math.max(ry1, ry2)
    ) ||
    pointInRectangle(
      lx2,
      ly2,
      Math.min(rx1, rx2),
      Math.min(ry1, ry2),
      Math.max(rx1, rx2),
      Math.max(ry1, ry2)
    )
  );
};
