import { useCallback, useRef, useState } from "react";
import { Layer, Rect } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import {
  selectConnections,
  selectShapes,
} from "../../features/diagram/diagramSlice";

import {
  addToSelection,
  removeFromSelection,
  resetSelection,
  selectSelection,
} from "../../features/selection/selectionSlice";
import { darkTheme } from "../../theme";
import {
  getMouseStagePosition,
  isElementSelected,
} from "../../utils/diagramUtils";

const SelectionLayer = ({ stage }) => {
  const dispatch = useDispatch();
  const layerRef = useRef();
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionLast, setSelectionLast] = useState(null);
  const shapes = useSelector(selectShapes);
  const connections = useSelector(selectConnections);
  const selection = useSelector(selectSelection);

  ////////////////////////////////
  // MOUSE DOWN
  ////////////////////////////////
  const handleMouseDown = useCallback(
    (e) => {
      layerRef.current.moveToTop();
      if (!e.evt.shiftKey && Object.keys(selection).length > 0) {
        dispatch(resetSelection());
      }
      setSelectionStart(getMouseStagePosition(e, stage));
    },
    [dispatch, selection, stage]
  );

  ////////////////////////////////
  // MOUSE MOVE
  ////////////////////////////////
  const handleMouseMove = useCallback(
    (e) => {
      if (e.evt.buttons === 1)
        setSelectionLast(getMouseStagePosition(e, stage));
    },
    [stage]
  );

  ////////////////////////////////
  // MOUSE UP
  ////////////////////////////////
  const handleMouseUp = useCallback(
    (e) => {
      layerRef.current.moveToBottom();
      if (selectionStart && selectionLast) {
        // Finishing selection, selecting all
        const selectedElementsIds = [
          ...Object.values(shapes),
          ...Object.values(connections),
        ]
          .filter((element) =>
            isElementSelected(element, selectionStart, selectionLast)
          )
          .map((element) => element.id);
        const elementsToDedelect = selectedElementsIds.filter(
          (id) => selection[id]
        );
        if (elementsToDedelect.length > 0)
          dispatch(removeFromSelection(elementsToDedelect));
        const elementsToSelect = selectedElementsIds.filter(
          (id) => !selection[id]
        );
        if (elementsToSelect.length > 0)
          dispatch(addToSelection(elementsToSelect));
      }
      setSelectionStart(null);
      setSelectionLast(null);
    },
    [connections, dispatch, selection, selectionLast, selectionStart, shapes]
  );

  ////////////////////////////////
  // RENDERING
  ////////////////////////////////
  return (
    <Layer
      ref={layerRef}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {stage && (
        <Rect
          x={-stage.x() / stage.scaleX()}
          y={-stage.y() / stage.scaleY()}
          width={stage.width() / stage.scaleX()}
          height={stage.height() / stage.scaleY()}
        />
      )}
      {selectionStart && selectionLast && (
        <Rect
          x={Math.min(selectionStart.x, selectionLast.x)}
          y={Math.min(selectionStart.y, selectionLast.y)}
          width={Math.abs(selectionLast.x - selectionStart.x)}
          height={Math.abs(selectionLast.y - selectionStart.y)}
          fill="#ff7a0055"
          stroke={darkTheme.palette.secondary.main}
          strokeWidth={1}
          listening={false}
        />
      )}
    </Layer>
  );
};

export default SelectionLayer;
