import { useCallback, useState } from "react";
import BaseLine from "../../BaseLine/BaseLine";

const SelectableLine = ({ connection, hoverable, selected, onOpenTools }) => {
  const [hovering, setHovering] = useState(false);
  const handleContextMenu = useCallback(
    (e) => {
      e.evt.preventDefault();
      e.evt.stopPropagation();
      onOpenTools(connection.id, { left: e.evt.clientX, top: e.evt.clientY });
    },
    [onOpenTools, connection.id]
  );
  return (
    <BaseLine
      id={connection.id}
      points={[
        connection.start.x,
        connection.start.y,
        connection.end.x,
        connection.end.y,
      ]}
      startArrow={!!connection.startArrow}
      endArrow={!!connection.endArrow}
      selected={selected}
      hovering={hovering}
      setHovering={(bool) => hoverable && setHovering(bool)}
      handleContextMenu={handleContextMenu}
    />
  );
};

SelectableLine.defaultProps = {
  hoverable: true,
};

export default SelectableLine;
