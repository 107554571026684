import React, { useCallback, useEffect } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  LinearProgress,
  List,
  ListItem,
  TextField,
  ToggleButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDiagram,
  setDiagram,
  updateDiagram,
} from "../../features/diagram/diagramSlice";
import ShareDialog from "../ShareDialog/ShareDialog";
import { isLoading } from "../../features/global/globalSlice";
import {
  selectUser,
  setUser,
  signinAnonymously,
} from "../../features/authentication/authenticationSlice";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/firebase";
import "firebaseui/dist/firebaseui.css";
import SignInDialog from "../../features/authentication/SignInDialog";
import Logo from "../../assets/logo.svg";

const maxTitleLength = 32;

const TopMenu = ({ editable, handleSwitchTheme, darkTheme }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const diagram = useSelector(selectDiagram);
  const loading = useSelector(isLoading);
  const handleNameSubmit = useCallback(
    (e) =>
      dispatch(
        updateDiagram({
          diagramId: params.diagramId,
          diagram: { title: e.target.value.substring(0, maxTitleLength) },
        })
      ),
    [dispatch, params.diagramId]
  );
  const handlePublicChange = useCallback(
    (e) =>
      dispatch(
        updateDiagram({
          diagramId: params.diagramId,
          diagram: { public: !diagram.public },
        })
      ),
    [diagram?.public, dispatch, params.diagramId]
  );
  const handleNameChange = useCallback(
    (e) =>
      dispatch(
        setDiagram({
          diagram: { title: e.target.value.substring(0, maxTitleLength) },
        })
      ),
    [dispatch]
  );
  const handleNameEnter = useCallback(
    (e) => e.key === "Enter" && e.target.blur(),
    []
  );
  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        const {
          displayName,
          email,
          emailVerified,
          photoURL,
          uid,
          isAnonymous,
        } = user;
        dispatch(
          setUser({
            displayName,
            email,
            emailVerified,
            photoURL,
            uid,
            isAnonymous,
          })
        );
      } else {
        dispatch(setUser(null));
        dispatch(signinAnonymously());
      }
    });
  }, [dispatch]);
  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <MobileToolbar
            diagramId={params.diagramId}
            diagram={diagram}
            user={user}
            editable={editable}
            darkTheme={darkTheme}
            handleSwitchTheme={handleSwitchTheme}
            handleNameChange={handleNameChange}
            handleNameEnter={handleNameEnter}
            handleNameSubmit={handleNameSubmit}
            handlePublicChange={handlePublicChange}
            anchorElNav={anchorElNav}
            handleOpenNavMenu={handleOpenNavMenu}
            handleCloseNavMenu={handleCloseNavMenu}
          />
          <DesktopToolbar
            diagramId={params.diagramId}
            diagram={diagram}
            user={user}
            editable={editable}
            darkTheme={darkTheme}
            handleSwitchTheme={handleSwitchTheme}
            handleNameChange={handleNameChange}
            handleNameEnter={handleNameEnter}
            handleNameSubmit={handleNameSubmit}
            handlePublicChange={handlePublicChange}
          />

          <Box flexGrow="1" />
          {params.diagramId && diagram && editable && (
            <Box pl="16px">
              <ShareDialog diagramId={params.diagramId} />
            </Box>
          )}
          <Box pl="16px">
            <SignInDialog />
          </Box>
        </Toolbar>
      </AppBar>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: "48px",
            width: "100vw",
            zIndex: "50",
          }}
        />
      )}
    </>
  );
};

const MobileToolbar = ({
  diagramId,
  diagram,
  user,
  editable,
  darkTheme,
  handleSwitchTheme,
  handleNameChange,
  handleNameEnter,
  handleNameSubmit,
  handlePublicChange,
  anchorElNav,
  handleOpenNavMenu,
  handleCloseNavMenu,
}) => (
  <Box sx={{ display: { xs: "flex", sm: "none" } }}>
    <img
      src={Logo}
      alt="Kids Play Safer Logo"
      width={32}
      height={32}
      onClick={handleOpenNavMenu}
    />
    <Drawer
      variant="temporary"
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
      }}
    >
      <List>
        <ListItem>
          <img
            src={Logo}
            alt="Kids Play Safer Logo"
            width={32}
            height={32}
            onClick={handleOpenNavMenu}
          />
          <Box pl="16px" mt="0px">
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <Typography variant="h6">anydiagram.io</Typography>
            </Link>
          </Box>
        </ListItem>
        <ListItem>
          <Divider />
        </ListItem>
        {diagramId && diagram && (
          <ListItem>
            <TextField
              variant="outlined"
              sx={{
                margin: "0px !important",
              }}
              InputProps={{
                sx: {
                  height: "32px",
                  fontSize: "14px",
                },
              }}
              value={diagram?.title || ""}
              placeholder={"Name your diagram"}
              onChange={handleNameChange}
              onKeyUp={handleNameEnter}
              onBlur={handleNameSubmit}
              disabled={!editable}
              fullWidth
            />
          </ListItem>
        )}
        <ListItem>
          <LockButton handlePublicChange={handlePublicChange} />
          <VisibilityButton handleVisibilityChange={() => {}} />
          <ThemeColorButton
            darkTheme={darkTheme}
            handleSwitchTheme={handleSwitchTheme}
          />
        </ListItem>
      </List>
    </Drawer>
  </Box>
);

const DesktopToolbar = ({
  diagramId,
  diagram,
  user,
  editable,
  darkTheme,
  handleSwitchTheme,
  handleNameChange,
  handleNameEnter,
  handleNameSubmit,
  handlePublicChange,
}) => (
  <Box sx={{ flexGrow: 0, display: { xs: "none", sm: "flex" } }}>
    <img
      src={Logo}
      alt="Kids Play Safer Logo"
      width={32}
      height={32}
      style={{ marginLeft: "-8px" }}
    />
    <Box pl="16px" mt="0px">
      <Link to="/" style={{ color: "white", textDecoration: "none" }}>
        <Typography variant="h6">anydiagram.io</Typography>
      </Link>
    </Box>
    {diagramId && diagram && (
      <Box pl="16px" mt="0px">
        <TextField
          variant="outlined"
          sx={{
            margin: "0px !important",
          }}
          InputProps={{
            sx: {
              height: "32px",
              fontSize: "14px",
            },
          }}
          value={diagram?.title || ""}
          placeholder={"Name your diagram"}
          onChange={handleNameChange}
          onKeyUp={handleNameEnter}
          onBlur={handleNameSubmit}
          disabled={!editable}
        />
      </Box>
    )}
    <Box pl="16px">
      <LockButton handlePublicChange={handlePublicChange} />
    </Box>
    <Box pl="16px">
      <VisibilityButton handleVisibilityChange={() => {}} />
    </Box>
    <Box pl="16px">
      <ThemeColorButton
        darkTheme={darkTheme}
        handleSwitchTheme={handleSwitchTheme}
      />
    </Box>
  </Box>
);

const LockButton = ({ handlePublicChange }) => {
  const user = useSelector(selectUser);
  const diagram = useSelector(selectDiagram);
  if (!diagram) return null;
  return (
    <Tooltip
      title={
        <>
          <Box>
            {diagram.public
              ? "Public updates: Anyone with the link can edit this diagram"
              : "Private updates: Only you can edit this diagram"}
          </Box>
          {user?.isAnonymous && (
            <Box>Anonymous users cannot change this setting</Box>
          )}
          {!user?.isAnonymous && diagram.owner !== user?.uid && (
            <Box>Only the diagram owner can change this setting</Box>
          )}
        </>
      }
    >
      <span>
        <ToggleButton
          value="check"
          size="small"
          selected={!diagram.public}
          onChange={handlePublicChange}
          disabled={user?.isAnonymous || diagram.owner !== user?.uid}
        >
          {diagram.public && <LockOpenIcon fontSize="inherit" />}
          {!diagram.public && <LockIcon fontSize="inherit" />}
        </ToggleButton>
      </span>
    </Tooltip>
  );
};

const VisibilityButton = () => {
  const user = useSelector(selectUser);
  const diagram = useSelector(selectDiagram);
  if (!diagram) return null;
  return (
    <Tooltip
      title={
        <>
          <Box>
            {diagram.readable
              ? "Public visibility: Anyone with the link can view this diagram"
              : "Private visibility: Only you can view this diagram"}
          </Box>
          {user?.isAnonymous && (
            <Box>Anonymous users cannot change this setting</Box>
          )}
          {!user?.isAnonymous && diagram.owner !== user?.uid && (
            <Box>Only the diagram owner can change this setting</Box>
          )}
          <Box>This feature is currently on our roadmap, stay tuned!</Box>
        </>
      }
    >
      <span>
        <ToggleButton
          value="check"
          size="small"
          selected={!diagram.readable}
          onChange={() => {}}
          disabled
        >
          {diagram.readable && <VisibilityIcon fontSize="inherit" />}
          {!diagram.readable && <VisibilityOffIcon fontSize="inherit" />}
        </ToggleButton>
      </span>
    </Tooltip>
  );
};

const ThemeColorButton = ({ darkTheme, handleSwitchTheme }) => {
  const diagram = useSelector(selectDiagram);
  if (!diagram) return null;
  return (
    <Tooltip title={darkTheme ? "Dark mode" : "Light mode"}>
      <span>
        <ToggleButton
          value="check"
          size="small"
          selected={darkTheme}
          onChange={handleSwitchTheme}
        >
          {!darkTheme && <LightModeIcon fontSize="inherit" />}
          {darkTheme && <DarkModeOutlinedIcon fontSize="inherit" />}
        </ToggleButton>
      </span>
    </Tooltip>
  );
};
export default TopMenu;
