import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import selectionReducer from "../features/selection/selectionSlice";
import diagramReducer from "../features/diagram/diagramSlice";
import authenticationReducer from "../features/authentication/authenticationSlice";
import editingReducer from "../features/editing/editingSlice";
import insertionReducer from "../features/insertion/insertionSlice";
import { isLocal } from "../utils/envUtils";

const logger = createLogger({
  collapsed: true,
  // predicate: (getState, action) => true, // Uncomment to disable logging
  duration: true,
});

export const store = configureStore({
  reducer: {
    diagram: diagramReducer,
    authentication: authenticationReducer,
    selection: selectionReducer,
    editing: editingReducer,
    insertion: insertionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    isLocal() ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});
