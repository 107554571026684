import { useCallback, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ShareDialog = ({ diagramId }) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const handleShareClick = useCallback(() => setShareModalOpen(true), []);
  const handleShareClose = useCallback(() => setShareModalOpen(false), []);
  const link = window.location.href;
  const iframe = `<iframe src="${window.location.protocol}//${window.location.hostname}/embeds/${diagramId}" title="anydiagram.io"
  frameborder="0" allowfullscreen width="800" height="600"></iframe>`;
  const handleCopyLinkToClipboard = useCallback(() => {
    navigator.clipboard.writeText(link);
    setConfirmationOpen(true);
  }, [link]);
  const handleCopyIFrameToClipboard = useCallback(() => {
    navigator.clipboard.writeText(iframe);
    setConfirmationOpen(true);
  }, [iframe]);
  const handleCloseConfirmation = useCallback(
    () => setConfirmationOpen(false),
    []
  );
  return (
    <>
      <Button variant="outlined" onClick={handleShareClick}>
        Share
      </Button>
      <Dialog
        // fullWidth
        open={shareModalOpen}
        onClose={handleShareClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Share your diagram</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Share a link
          </DialogContentText>
          <Box
            border="1px solid rgba(255, 255, 255, 0.23)"
            borderRadius="4px"
            p="4px 8px 4px 4px"
            mt="8px"
            display="flex"
          >
            <Typography
              sx={{ p: "4px", fontFamily: "Courier", wordBreak: "break-word" }}
            >
              {link}
            </Typography>
            <Box flexGrow={1} />
            <IconButton
              aria-label="copy"
              size="small"
              onClick={handleCopyLinkToClipboard}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box mt="16px" />
          <DialogContentText id="alert-dialog-description">
            Embed in any page, confluence, ...
          </DialogContentText>
          <Box
            border="1px solid rgba(255, 255, 255, 0.23)"
            borderRadius="4px"
            p="4px 8px 4px 4px"
            mt="8px"
            display="flex"
            alignItems="flex-end"
          >
            <Typography
              sx={{ p: "4px", fontFamily: "Courier", wordBreak: "break-word" }}
            >
              {iframe}
            </Typography>
            <Box flexGrow={1} />
            <IconButton
              aria-label="copy"
              size="small"
              onClick={handleCopyIFrameToClipboard}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: "0px 24px 16px 24px" }}>
          <Button variant="outlined" onClick={handleShareClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={confirmationOpen}
        onClose={handleCloseConfirmation}
        autoHideDuration={2500}
        message="Copied to clipboard"
      />
    </>
  );
};

export default ShareDialog;
