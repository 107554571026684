import { FlipToBackOutlined, FlipToFrontOutlined } from "@mui/icons-material";
import {
  ButtonGroup,
  IconButton,
  Paper,
  Popover,
  Stack,
  ToggleButton,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { isConnection } from "../../utils/diagramUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDiagramId,
  selectElementCreator,
  updateElements,
} from "../../features/diagram/diagramSlice";

const ToolPoppover = ({
  stageRef,
  shapeId,
  open,
  onClose,
  anchorPosition,
  moveShapeIndex,
}) => {
  const dispatch = useDispatch();
  const diagramId = useSelector(selectDiagramId);
  const element = useSelector(selectElementCreator(shapeId));
  return (
    <Popover
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={onClose}
      placement="top"
    >
      <Paper sx={{ p: "4px" }}>
        <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled elevation buttons"
        >
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={() => {
                moveShapeIndex(shapeId, -2);
              }}
            >
              <FlipToBackOutlined />
            </IconButton>
            <IconButton
              onClick={() => {
                moveShapeIndex(shapeId, 2);
              }}
            >
              <FlipToFrontOutlined />
            </IconButton>
            {isConnection(element) && (
              <ToggleButton
                value="check"
                size="small"
                selected={element.startArrow}
                onChange={() => {
                  dispatch(
                    updateElements({
                      diagramId,
                      elements: [
                        { id: element.id, startArrow: !element.startArrow },
                      ],
                    })
                  );
                }}
              >
                <WestIcon fontSize="inherit" />
              </ToggleButton>
            )}
            {isConnection(element) && (
              <ToggleButton
                value="check"
                size="small"
                selected={element.endArrow}
                onChange={() => {
                  dispatch(
                    updateElements({
                      diagramId,
                      elements: [
                        { id: element.id, endArrow: !element.endArrow },
                      ],
                    })
                  );
                }}
              >
                <EastIcon fontSize="inherit" />
              </ToggleButton>
            )}
          </Stack>
        </ButtonGroup>
      </Paper>
    </Popover>
  );
};

export default ToolPoppover;
