import KeyMirror from "keymirror";
import { gridSize } from "../components/Diagram/GridLayer";
import {
  lineIntersectsRectangle,
  manhattanDistance,
  rectangleIntersectsRectangle,
} from "./geometryUtils";

const SHAPES = KeyMirror({
  RECT: null,
  CONTAINER: null,
  DECISION: null,
  ACTOR: null,
});

const CONNECTIONS = KeyMirror({
  LINE: null,
  ORTHOGONAL_CONNECTION: null,
});

export const ITEM_TYPES = { ...SHAPES, ...CONNECTIONS };

export const filterNotNull = (e) => !!e;

export const isShape = (element) => !!element && !!SHAPES[element.type];

export const isConnection = (element) =>
  !!element && !!CONNECTIONS[element.type];

export const generateShape = (type, shape, inPlace) => {
  const x = (shape?.x || 0) + (inPlace ? 0 : gridSize);
  const y = (shape?.y || 0) + (inPlace ? 0 : gridSize);
  const width = shape?.width || 96;
  const height = shape?.height || 32;
  switch (type) {
    case ITEM_TYPES.RECT:
    case ITEM_TYPES.CONTAINER:
      return {
        ...shape,
        type,
        text: shape?.text || "",
        anchors: [
          { position: "top-left" },
          { position: "top" },
          { position: "top-right" },
          { position: "right-top" },
          { position: "right" },
          { position: "right-bottom" },
          { position: "bottom-right" },
          { position: "bottom" },
          { position: "bottom-left" },
          { position: "left-bottom" },
          { position: "left" },
          { position: "left-top" },
        ],
        x,
        y,
        width,
        height,
      };
    case ITEM_TYPES.DECISION:
      return {
        ...shape,
        type,
        text: shape?.text || "",
        anchors: [
          { position: "top" },
          { position: "right" },
          { position: "bottom" },
          { position: "left" },
        ],
        x,
        y,
        width: width,
        height: width,
      };
    case ITEM_TYPES.ACTOR:
      return {
        ...shape,
        type,
        text: shape?.text || "",
        anchors: [
          { position: "top-left" },
          { position: "top" },
          { position: "top-right" },
          { position: "right-top" },
          { position: "right" },
          { position: "right-bottom" },
          { position: "bottom-right" },
          { position: "bottom" },
          { position: "bottom-left" },
          { position: "left-bottom" },
          { position: "left" },
          { position: "left-top" },
        ],
        x,
        y,
        width: height,
        height: height * 2,
      };
    case ITEM_TYPES.LINE:
    case ITEM_TYPES.ORTHOGONAL_CONNECTION:
      return {
        ...shape,
        startAnchor: null,
        endAnchor: null,
        type,
        text: shape?.text || "",
        start: {
          x: shape?.start?.x + (inPlace ? 0 : gridSize),
          y: shape?.start?.y + (inPlace ? 0 : gridSize),
        },
        end: {
          x: shape?.end?.x + (inPlace ? 0 : gridSize),
          y: shape?.end?.y + (inPlace ? 0 : gridSize),
        },
      };
    default:
      return null;
  }
};

export const calculateAnchorCoordinates = (shapeData, position) => {
  if (position === "top-left")
    return {
      x: shapeData.x + shapeData.width / 4,
      y: shapeData.y,
    };
  if (position === "top")
    return {
      x: shapeData.x + shapeData.width / 2,
      y: shapeData.y,
    };
  if (position === "top-right")
    return {
      x: shapeData.x + (shapeData.width / 4) * 3,
      y: shapeData.y,
    };
  if (position === "right-top")
    return {
      x: shapeData.x + shapeData.width,
      y: shapeData.y + shapeData.height / 4,
    };
  if (position === "right")
    return {
      x: shapeData.x + shapeData.width,
      y: shapeData.y + shapeData.height / 2,
    };
  if (position === "right-bottom")
    return {
      x: shapeData.x + shapeData.width,
      y: shapeData.y + (shapeData.height / 4) * 3,
    };
  if (position === "bottom-left")
    return {
      x: shapeData.x + shapeData.width / 4,
      y: shapeData.y + shapeData.height,
    };
  if (position === "bottom")
    return {
      x: shapeData.x + shapeData.width / 2,
      y: shapeData.y + shapeData.height,
    };
  if (position === "bottom-right")
    return {
      x: shapeData.x + (shapeData.width / 4) * 3,
      y: shapeData.y + shapeData.height,
    };
  if (position === "left-top")
    return {
      x: shapeData.x,
      y: shapeData.y + shapeData.height / 4,
    };
  if (position === "left")
    return {
      x: shapeData.x,
      y: shapeData.y + shapeData.height / 2,
    };
  if (position === "left-bottom")
    return {
      x: shapeData.x,
      y: shapeData.y + (shapeData.height / 4) * 3,
    };
};

export const minSnapDistance = 10;
export const findClosestAnchor = (anchors, position) => {
  return anchors.reduce(
    ([closest, closestDistance], current) => {
      const currentDistance = manhattanDistance(current, position);
      if (currentDistance < closestDistance) return [current, currentDistance];
      return [closest, closestDistance];
    },
    [null, Infinity]
  );
};

export const calculateOrthogonalPoints = (connection) => {
  const distX = connection.end.x - connection.start.x;
  const distY = connection.end.y - connection.start.y;
  const startPosition = connection.startAnchor?.position ?? "";
  const endPosition = connection.endAnchor?.position ?? "";
  const startVertical =
    startPosition.startsWith("top") || startPosition.startsWith("bottom");
  const startHorizontal =
    startPosition.startsWith("left") || startPosition.startsWith("right");
  const endVertical =
    endPosition.startsWith("top") || endPosition.startsWith("bottom");
  const endHorizontal =
    endPosition.startsWith("left") || endPosition.startsWith("right");
  if (startPosition.startsWith("left") && endPosition.startsWith("left")) {
    return [
      connection.start.x,
      connection.start.y,
      Math.min(connection.start.x, connection.end.x) - 16,
      connection.start.y,
      Math.min(connection.start.x, connection.end.x) - 16,
      connection.end.y,
      connection.end.x,
      connection.end.y,
    ];
  } else if (
    startPosition.startsWith("right") &&
    endPosition.startsWith("right")
  ) {
    return [
      connection.start.x,
      connection.start.y,
      Math.max(connection.start.x, connection.end.x) + 16,
      connection.start.y,
      Math.max(connection.start.x, connection.end.x) + 16,
      connection.end.y,
      connection.end.x,
      connection.end.y,
    ];
  } else if (startPosition.startsWith("top") && endPosition.startsWith("top")) {
    return [
      connection.start.x,
      connection.start.y,
      connection.start.x,
      Math.min(connection.start.y, connection.end.y) - 16,
      connection.end.x,
      Math.min(connection.start.y, connection.end.y) - 16,
      connection.end.x,
      connection.end.y,
    ];
  } else if (
    startPosition.startsWith("bottom") &&
    endPosition.startsWith("bottom")
  ) {
    return [
      connection.start.x,
      connection.start.y,
      connection.start.x,
      Math.max(connection.start.y, connection.end.y) + 16,
      connection.end.x,
      Math.max(connection.start.y, connection.end.y) + 16,
      connection.end.x,
      connection.end.y,
    ];
  } else if (startVertical && endVertical) {
    return [
      connection.start.x,
      connection.start.y,
      connection.start.x,
      connection.start.y + distY / 2,
      connection.end.x,
      connection.start.y + distY / 2,
      connection.end.x,
      connection.end.y,
    ];
  } else if (startHorizontal && endHorizontal) {
    return [
      connection.start.x,
      connection.start.y,
      connection.start.x + distX / 2,
      connection.start.y,
      connection.start.x + distX / 2,
      connection.end.y,
      connection.end.x,
      connection.end.y,
    ];
  } else if (
    (startVertical && !endVertical) ||
    (endHorizontal && !startHorizontal)
  ) {
    return [
      connection.start.x,
      connection.start.y,
      connection.start.x,
      connection.end.y,
      connection.end.x,
      connection.end.y,
    ];
  } else if (
    (startHorizontal && !endHorizontal) ||
    (endVertical && !startVertical)
  ) {
    return [
      connection.start.x,
      connection.start.y,
      connection.end.x,
      connection.start.y,
      connection.end.x,
      connection.end.y,
    ];
  } else if (Math.abs(distX) <= Math.abs(distY)) {
    return [
      connection.start.x,
      connection.start.y,
      connection.start.x,
      connection.end.y,
      connection.end.x,
      connection.end.y,
    ];
  } else if (Math.abs(distX) > Math.abs(distY)) {
    return [
      connection.start.x,
      connection.start.y,
      connection.end.x,
      connection.start.y,
      connection.end.x,
      connection.end.y,
    ];
  } else {
    // We should never enter this, but just in case... We draw a straight line.
    return [
      connection.start.x,
      connection.start.y,
      connection.end.x,
      connection.end.y,
    ];
  }
};

export const isElementSelected = (element, selectionStart, selectionLast) => {
  if (isShape(element)) {
    return rectangleIntersectsRectangle(
      element.x,
      element.y,
      element.x + element.width,
      element.y + element.height,
      Math.min(selectionStart.x, selectionLast.x),
      Math.min(selectionStart.y, selectionLast.y),
      Math.max(selectionStart.x, selectionLast.x),
      Math.max(selectionStart.y, selectionLast.y)
    );
  } else {
    // FIXME: This needs to be improved... Performance is meh
    if (element.type === ITEM_TYPES.ORTHOGONAL_CONNECTION) {
      const points = calculateOrthogonalPoints(element);
      for (let i = 0; i < points.length - 3; i += 2) {
        if (
          lineIntersectsRectangle(
            points[i],
            points[i + 1],
            points[i + 2],
            points[i + 3],
            selectionStart.x,
            selectionStart.y,
            selectionLast.x,
            selectionLast.y
          )
        ) {
          return true;
        }
      }
      return false;
    } else {
      return lineIntersectsRectangle(
        element.start.x,
        element.start.y,
        element.end.x,
        element.end.y,
        selectionStart.x,
        selectionStart.y,
        selectionLast.x,
        selectionLast.y
      );
    }
  }
};

export const getMouseStagePosition = (e, stage) => {
  return {
    x: Math.round(
      e.evt.layerX / stage.getScaleX() - stage.x() / stage.scaleX()
    ),
    y: Math.round(
      e.evt.layerY / stage.getScaleY() - stage.y() / stage.scaleY()
    ),
  };
};
