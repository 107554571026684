import { Box, Grid, Skeleton } from "@mui/material";
import { useCallback } from "react";
import { ITEM_TYPES } from "../../utils/diagramUtils";
import RectangleIcon from "@mui/icons-material/Rectangle";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "../../features/global/globalSlice";
import { resetSelection } from "../../features/selection/selectionSlice";
import { Square } from "@mui/icons-material";
import { startInsertion } from "../../features/insertion/insertionSlice";

export const leftMenuWidth = 66;

const LeftMenu = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const addElement = useCallback(
    (type) => {
      dispatch(resetSelection());
      dispatch(startInsertion(type));
    },
    [dispatch]
  );
  return (
    <Box
      width={`${leftMenuWidth}px`}
      minWidth={`${leftMenuWidth}px`}
      p="16px 16px"
      backgroundColor="#282828"
      color="white"
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <RectangleIcon
              fontSize="large"
              onClick={() => addElement(ITEM_TYPES.RECT)}
            />
          )}
        </Grid>
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <RectangleOutlinedIcon
              fontSize="large"
              onClick={() => addElement(ITEM_TYPES.CONTAINER)}
            />
          )}
        </Grid>
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <Square
              fontSize="large"
              onClick={() => addElement(ITEM_TYPES.DECISION)}
              sx={{ transform: "rotate(45deg)" }}
            />
          )}
        </Grid>
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="circular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <ShowChartIcon
              fontSize="large"
              onClick={() => addElement(ITEM_TYPES.ORTHOGONAL_CONNECTION)}
            />
          )}
        </Grid>
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="circular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <HorizontalRuleIcon
              fontSize="large"
              onClick={() => addElement(ITEM_TYPES.LINE)}
            />
          )}
        </Grid>
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <AccessibilityIcon
              fontSize="large"
              onClick={() => addElement(ITEM_TYPES.ACTOR)}
            />
          )}
        </Grid>
        <Grid item alignSelf="center" textAlign="center">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={35}
              height={31}
              sx={{ margin: "5px 0px" }}
            />
          ) : (
            <Box />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeftMenu;
